import request from '@/utils/request'

// 合作案例
export function getCases() {
  return request({
    url: '/cases/getCases',
    method: 'post'
  })
}

// 获取合作案例详情
export function getCase(data) {
  return request({
    url: '/cases/getCase',
    method: 'post',
    data
  })
}
