window.requestAnimationFrame || function () {
  'use strict';
  let timer;

  window.requestAnimationFrame = window.msRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || function () {

      const fps = 60;
      const delay = 1000 / fps;
      const animationStartTime = Date.now();
      let previousCallTime = animationStartTime;

      return function requestAnimationFrame(callback) {

        const requestTime = Date.now();
        const timeout = Math.max(0, delay - (requestTime - previousCallTime));
        const timeToCall = requestTime + timeout;

        previousCallTime = timeToCall;

        const timer = window.setTimeout(function onAnimationFrame() {

          callback(timeToCall - animationStartTime);

        }, timeout);
        return timer;
      };
    }();

  window.cancelAnimationFrame = window.mozCancelAnimationFrame
    || window.webkitCancelAnimationFrame
    || window.cancelRequestAnimationFrame
    || window.msCancelRequestAnimationFrame
    || window.mozCancelRequestAnimationFrame
    || window.webkitCancelRequestAnimationFrame
    || function cancelAnimationFrame(timer) {
      window.clearTimeout(timer);
    };

}();
