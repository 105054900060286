<template>
  <div class="m-home-cultures">
    <div class="cultures-ctnr">
      <div>
        <div class="mv-ctnr">
          <img class="mv" src="../../assets/mv.png" alt="" />
          <span class="text">365MV</span>
        </div>

        <div class="video">
          <Video></Video>
        </div>
      </div>

      <div class="cultures-ct">
        <p class="c1">{{ cultures.c1 }}</p>
        <p class="c2">{{ cultures.c2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Video from '../home/Video';
const cultures = {
  en: {
    c1:
      'Loyalty — conscientious and conscientious, to provide quality bodyguard service',
    c2: 'Mission — defend to the death, create excellent industry brand',
  },
  cn: {
    c1: '忠诚——尽责尽职，提供优质的保镖服务',
    c2: '使命——誓死捍卫，打造优秀的行业品牌',
  },
  ja: {
    c1:
      '忠誠 -- 責任を尽くして職務を果たし、優れたボディーガードサービスを提供する',
    c2: '使命 -- 誓いを立てて守り、優れた業界ブランドを作る',
  },
  ko: {
    c1: '충성——책임을 다하고 양질의 경호서비스를 제공합니다',
    c2: '사명——목숨을 걸고 수호하여 우수한 업종브랜드를 창조한다',
  },
  hi: {
    c1:
      'वफादारी——ईमानदार और कर्तव्य-पूर्ण, गुणवत्ता अंगरक्षक सेवाएं प्रदान करना',
    c2: 'मिशन——एक उत्कृष्ट उद्योग ब्रांड के निर्माण के लिए मृत्यु की रक्षा।',
  },
};
export default {
  components: {
    Video,
  },
  data() {
    let locale = this.$i18n.locale;
    let currentCulture = {};

    if (locale === 'en-US') {
      currentCulture = cultures.en;
    } else if (locale === 'zh-Hans-CN') {
      currentCulture = cultures.cn;
    } else if (locale === 'ja') {
      currentCulture = cultures.ja;
    } else if (locale === 'ko') {
      currentCulture = cultures.ko;
    } else if (locale === 'hi') {
      currentCulture = cultures.hi;
    }

    return {
      cultures: currentCulture,
    };
  },
};
</script>

<style lang="scss">
.m-home-cultures {
  max-width: 100%;
  background-image: url('https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/15.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  .cultures-ctnr {
    margin: 0 auto;
    display: block;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    max-width: 120rem;
    padding: 6rem 0 8rem;
    .mv-ctnr {
      padding: 0 0 2rem 1rem;
      display: flex;
      align-items: flex-end;
      .mv {
        margin-right: 1rem;
        width: 5rem;
      }
      .text {
        color: #ebc83e;
        font-size: 3rem;
      }
    }

    .video {
      float: left;
      max-width: 60rem;
    }
    .cultures-ct {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 4rem;
      max-width: 60rem;
      color: #eee;
      .c1 {
        font-size: 3rem;
        font-weight: 600;
      }
      .c2 {
        font-size: 3rem;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .m-home-cultures {
    // max-width: ;
    // .cultures-ctnr {
    //   padding: 3rem 2rem;
    //   // height: 16rem;
    //   // background-image: url("../../assets/home/11.png");
    //   // background-repeat: no-repeat;
    //   .cultures-ct {
    //     .c1 {
    //       padding: 2rem 0;
    //       font-size: 1.5rem;
    //       font-weight: 600;
    //     }
    //     .c2 {
    //       padding: 1rem 0;
    //       font-size: 1.5rem;
    //       text-indent: 2rem;
    //       font-weight: 600;
    //     }
    //   }
    // }
    .cultures-ctnr {
      margin: 0 auto;
      display: block;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 120rem;
      padding: 0 0;
      .mv-ctnr {
        padding: 1rem;
        display: flex;
        align-items: flex-end;
        .mv {
          margin-right: 0.5rem;
          width: 3rem;
        }
        .text {
          color: #ebc83e;
          font-size: 2rem;
          line-height: 2rem;
        }
      }

      .video {
        margin: 0 auto;
        // float: left;
        max-width: 37.5rem;
      }
      .cultures-ct {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0 auto;
        max-width: 35rem;
        color: #eee;
        .c1 {
          // line-height: 3rem;
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        .c2 {
          // line-height: 3rem;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
