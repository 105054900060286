<template>
  <div class="m-header">
    <div class="header-container">
      <HeaderTop></HeaderTop>
      <div class="header-main">
        <HeaderMain></HeaderMain>
      </div>
      <HeaderMenu></HeaderMenu>
    </div>
  </div>
</template>

<script>
import HeaderTop from './Top';
import HeaderMain from './Main';
import HeaderMenu from './Menu';

export default {
  components: {HeaderTop, HeaderMain, HeaderMenu },
  computed: {
    contactTitle() {
      return this.$t('other.contact');
    }
  }
}
</script>

<style lang="scss" scoped>
$headerHeight: 20rem;

.m-header {
  position: fixed;
  left: 0;
  top: 0;
  height: $headerHeight;
  width: 100%;
  background-color: #1b1b1b;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
  z-index: 1000;
  .header-container {
    position: relative;
    height: 100%;
    .header-main {
      margin: 0 auto;
      max-width: 120rem;
      padding: 0 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .m-header {
    .header-container {
      .header-main {
        padding: 0 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .m-header {
    display: none;
  }
}

</style>
