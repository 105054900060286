<template>
  <div class="p-home">
    <Banners ref="home"></Banners>
    <Services ref="services"></Services>
    <Cultures ref="cultures"></Cultures>

    <Success ref="successfulCase"></Success>
    <Cooperation ref="cooperation"></Cooperation>
    <Consult ref="consult"></Consult>
    <Information ref="information"></Information>
    <About ref="about"></About>
    <div class="select-lang" v-if="showDialog && !selectedLanguage">
      <p class="title title-US">{{ $t('other.title', 'en-US') }}</p>
      <div class="languages-ct">
        <div
          v-for="language in languageOptions"
          :key="language.code"
          :class="language.class"
          @click="selectLanguage(language.code)"
        >
          <div>
            <img class="image" :src="language.image" :alt="language.name" />
          </div>
          <p>{{ language.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRootPath } from '@/utils';
import { languages } from '@/config/language';
import scrollMixins from '@/mixins/scroll';
import sectionMixins from '@/mixins/section';
import Banners from './Banners';
import Services from './Services';
import About from './About';
import Cooperation from './Cooperation';
import Success from './Success';
import Cultures from './Cultures';
import Consult from './Consult';
import Information from './Information';
import { qs2obj } from '@/utils';

export default {
  components: {
    Banners,
    Services,
    About,
    Cooperation,
    Success,
    Cultures,
    Consult,
    Information,
  },
  mixins: [scrollMixins, sectionMixins],
  data() {
    return {
      languageOptions: [
        {
          code: 'en-US',
          class: 'US',
          image:
            'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag1.png',
          name: 'English',
        },
        {
          code: 'zh-Hans-CN',
          class: 'ZH',
          image:
            'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag2.png',
          name: '中文',
        },
        {
          code: 'ja',
          class: 'JA',
          image:
            'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag3.png',
          name: '日本語',
        },
        {
          code: 'ko',
          class: 'KO',
          image:
            'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag5.png',
          name: '한국어',
        },
        {
          code: 'hi',
          class: 'HI',
          image:
            'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag4.png',
          name: 'हिन्दी',
        },
      ],
      sectionsData: [],
      scrollBox: null,
      scrollBoxEl: null,
      selectedLanguage: '',
      showDialog: false,
    };
  },
  created() {},

  mounted() {
    const hasOpened = sessionStorage.getItem('hasOpened');
    if (!hasOpened) {
      setTimeout(() => {
        this.showDialog = true;
        setTimeout(() => {
          this.showDialog = false;
        }, 3000);
        sessionStorage.setItem('hasOpened', 'true');
      }, 1000);
    }

    this.setSectionData([
      {
        name: 'home',
        el: this.$refs.home,
      },
      {
        name: 'services',
        el: this.$refs.services,
      },

      {
        name: 'successfulCase',
        el: this.$refs.successfulCase,
      },
      {
        name: 'cooperation',
        el: this.$refs.cooperation,
      },
      {
        name: 'cultures',
        el: this.$refs.cultures,
      },
      {
        name: 'consult',
        el: this.$refs.consult,
      },
      {
        name: 'information',
        el: this.$refs.information,
      },
      {
        name: 'about',
        el: this.$refs.about,
      },
    ]);

    setTimeout(() => {
      const search = window.location.search;

      if (search) {
        const pairs = qs2obj(search);

        if (pairs && pairs.section) {
          this.toSection(pairs.section);
        }
      }
    }, 0);
  },
  methods: {
    selectLanguage(languageValue) {
      this.showDialog = false;
      const rootPath = getRootPath();
      let routePath, languagePath;

      const currLanguage = languages.find(
        (language) => language.value === rootPath
      );

      if (
        rootPath === languageValue ||
        (!currLanguage && languageValue === languages[0].value)
      )
        return;

      if (currLanguage) {
        routePath = this.$route.fullPath.split('/').slice(2).join('/');
      } else {
        routePath = this.$route.fullPath;
      }

      if (languageValue === languages[0].value) {
        languagePath = '';
      } else {
        languagePath = languageValue;
      }

      window.location.href =
        window.location.origin +
        (this.$router.options.base || '/') +
        languagePath +
        routePath;
    },
    setCurrentSection(section) {
      this.$store.actions.setCurrentSection(section.name);
    },
    setSectionController(controller) {
      this.$store.mutations.setSectionController(controller);
    },
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.p-home {
  position: relative;
  width: 100%;
  .select-lang {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5rem;
    background-color: rgb(248, 248, 248);
    border-radius: 1rem;
    z-index: 2;

    .title {
      text-align: center;
      font-size: 1.8rem;
      line-height: 3rem;
    }
    .title-US {
      margin-top: 0.5rem;
      font-size: 2.2rem;
    }
    .select {
      margin-top: 2rem;
      font-size: 2.2rem;
      text-align: center;
    }
    .select-one {
      margin-top: 0.5rem;
      font-size: 1.8rem;
    }
    .languages-ct {
      padding: 3rem 0;
      margin-top: 3rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .ZH,
      .US,
      .JA,
      .KO,
      .HI {
        padding: 1rem;
        width: 25%;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: transform 0.5s; // 添加过渡效果，使放大过程平滑

        &:hover {
          transform: scale(1.2); // 鼠标悬停时放大1.2倍
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .p-home {
    width: 100%;
    .select-lang {
      position: fixed;
      top: 40%;
      left: 20%;
      transform: translate(-12%, -50%);
      // width: 40rem;
      padding: 2rem 1rem;
      .title {
        padding: 0rem;
        font-size: 1.2rem;
        line-height: 2.2rem;
        font-weight: 550;
      }
      .title-US {
        font-size: 1.5rem;
      }
      .select {
        text-align: center;
        font-size: 1.5rem;
      }
      .select-one {
        font-size: 1.2rem;
      }
      .languages-ct {
        padding: 0.5rem 0;
        margin-top: 0.4rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .ZH,
        .US,
        .JA,
        .KO,
        .HI {
          padding: 0.4rem;
          width: 30%;
          height: 10rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 1.1rem;
        }
      }
    }
  }
}
</style>
