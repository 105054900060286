<template>
  <div ref="container" class="m-home-about">
    <div class="about-ctnr">
      <div class="about-left">
        <p class="about-title about-us" @click="toAbout">
          {{ $t('menu.about') }}
        </p>
        <p class="introduce-title introduce" @click="toIntroduce">
          {{ $t('about.introduce') }}
        </p>
        <p class="honor">{{ $t('about.honor') }}</p>
        <p class="relation">{{ $t('about.relation') }}</p>
      </div>
      <div class="about-center">
        <p class="about-title">{{ $t('about.consult') }}</p>
        <p><i class="el-icon-phone"></i> +63 917 180 6668</p>
        <p>
          <i class="el-icon-location-outline"></i> {{ $t('about.site') }}:
          {{ $t('about.address') }}
          <a class="check" href="" @click="showMap">{{ $t('other.map') }}</a>
        </p>
        <p>
          <i class="el-icon-message"></i>
          {{ $t('about.mailbox') }}：security@365theone.com
        </p>
      </div>
      <!-- <div class="about-right">
        <el-form :model="formLabelAlign" ref="form" :rules="rules">
          <el-form-item >
            <el-input v-model="formLabelAlign.name" :placeholder="$t('about.name')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="formLabelAlign.phone" :placeholder="$t('about.Phone')"></el-input>
          </el-form-item>
          <el-form-item >
            <el-input type="textarea" v-model="formLabelAlign.content" :placeholder="$t('about.content')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">{{ $t('about.submit') }}</el-button>
          </el-form-item>
        </el-form>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getLanguageRoutePath } from '@/utils';
import { addMessage } from '@/api/message';
import animationMixin from '@/mixins/animations';

export default {
  mixins: [animationMixin],

  data() {
    return {
      formLabelAlign: {
        name: '',
        phone: '',
        content: '',
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            pattern: /^[0-9]{11}$/,
            message: '电话号码格式不正确',
            trigger: 'blur',
          },
        ],
        content: [
          { required: true, message: '请输入咨询内容', trigger: 'blur' },
          { max: 200, message: '咨询内容不能超过200个字符', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    showMap() {
      this.$router.push({ path: '/Map' });
      window.location.href =
        window.location.origin + getLanguageRoutePath('Map');
    },
    toAbout() {
      window.location.href =
        window.location.origin + getLanguageRoutePath('AboutUs');
    },
    toIntroduce() {
      window.location.href =
        window.location.origin + getLanguageRoutePath('Introduce');
    },
    async onSubmit() {
      await this.$refs.form.validate((valid) => {
        if (valid) {
          addMessage(this.formLabelAlign);
          //   重置表单
          this.formLabelAlign.name = '';
          this.formLabelAlign.phone = '';
          this.formLabelAlign.content = '';
          const messages = {
            'en-US':
              'Message submitted successfully, please wait for customer service to contact you',
            'zh-Hans-CN': '留言提交成功，请等待客服与您联系',
            ja: '伝言の提出に成功しました。お客様からの連絡をお待ちください',
            ko:
              '성공적으로 제출 된 메시지, 고객 서비스가 연락 할 때까지 기다리십시오.',
            hi:
              'संदेश प्रस्तुत सफलतापूर्वक, आप संपर्क करने के लिए ग्राहक सेवा के लिए कृपया प्रतीक्षा करें',
          };

          this.$message({
            type: 'success',
            message: messages[this.$i18n.locale],
            center: true,
            offset: 250,
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.m-home-about {
  width: 100%;
  margin: 0 auto;
  padding: 8rem 0 10rem;
  height: 45rem;
  background-color: #111111;
  background-image: url('https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/17.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .about-ctnr {
    max-width: 100rem;
    // max-width: 125rem;
    margin: 0rem auto;
    // background-color: #fff;
    display: flex;
    justify-content: space-between;
    .about-left {
      text-align: left;
      line-height: 5rem;
      //
    }
    .about-center {
      // padding: 4rem 0;
      max-width: 50rem;
      text-align: left;
      line-height: 5rem;
      .check {
        display: inline-block;
        border: 1px solid #ebc83e;
        margin-left: 5px;
        // background-color: #ebc83e;
        color: #ebc83e;
        border-radius: 0.4rem;
        padding: 0 1rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
      }
    }
    .about-right {
      .el-form-item__label {
        color: #ccc;
        font-size: 1.5rem;
      }
      .el-input {
        width: 30rem;
      }
      .el-input__inner,
      .el-textarea__inner {
        background-color: #111;
        color: #ccc;
      }
      .el-input__inner::placeholder {
        color: #ccc;
      }
      /* 修改输入框的聚焦状态 */
      .el-input__inner:focus,
      .el-textarea__inner:focus {
        border-color: #a62127;
        box-shadow: 0 0 0 2px rgba(233, 100, 91, 0.2);
      }
      .el-button {
        width: 30rem;
        background-color: #222;
      }
      .el-button--primary {
        border-color: #fff;
      }
    }
    p {
      font-size: 2rem;
      color: #fff;
    }
    .about-title {
      font-size: 3rem;
      font-weight: 600;
      cursor: pointer;
    }
    .introduce-title {
      font-size: 2.8rem;
      font-weight: 600;
      cursor: pointer;
    }
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes zoom {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2);
      }
    }
    .about-us {
      // cursor: pointer;
      animation: blink 2s ease-in-out infinite,
        zoom 2s ease-in-out infinite alternate;
    }
  }
}

@media screen and (max-width: 767px) {
  .m-home-about {
    height: 55rem;
    // height: 76rem;
    padding: 4rem 0 4rem;
    .about-ctnr {
      margin: 0rem auto;
      // background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .about-left {
        text-align: center;
        line-height: 3rem;
        max-width: 40rem;
        .honor,
        .relation {
          font-size: 1.6rem;
        }
      }
      .about-center {
        padding: 4rem 3rem;
        text-align: center;
        line-height: 3.5rem;
        .about-title {
          padding-bottom: 2rem;
        }
        .check {
          display: inline-block;
          border: 1px solid #ebc83e;
          margin-left: 5px;
          // background-color: #ebc83e;
          color: #ebc83e;
          border-radius: 0.4rem;
          padding: 0 1rem;
          font-size: 1.2rem;
          line-height: 2.2rem;
        }
      }
      .about-right {
        margin: auto;
        .el-form-item__label {
          color: #ccc;
          font-size: 1.5rem;
        }
        .el-input,
        .el-textarea {
          width: 22rem;
        }
        .el-input__inner,
        .el-textarea__inner {
          background-color: #111;
          color: #eee;
        }
        .el-input__inner::placeholder {
          color: #ccc;
        }
        /* 修改输入框的聚焦状态 */
        .el-input__inner:focus,
        .el-textarea__inner:focus {
          border-color: #a62127;
          box-shadow: 0 0 0 2px rgba(233, 100, 91, 0.2);
        }
        .el-button {
          width: 22rem;
          background-color: #222;
        }
        .el-button--primary {
          border-color: #fff;
        }
      }
      p {
        font-size: 1.5rem;
        color: #fff;
      }
      .about-title {
        font-size: 2rem;
        font-weight: 600;
      }
      .about-us {
        font-size: 2.5rem;
        line-height: 4rem;
      }
      .introduce-title {
        font-size: 2.3rem;
        line-height: 4rem;
      }
      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes zoom {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.2);
        }
      }
      .about-us {
        cursor: pointer;
        animation: blink 2s ease-in-out infinite,
          zoom 2s ease-in-out infinite alternate;
      }
    }
  }
}
</style>
