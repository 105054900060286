<template>
  <div ref="container" class="m-lazy-load-img" :style="`display: ${display}`">
    <div
      v-if="imgState !== 'finish' && miniImgState !== 'finish'"
      class="default-img"
      :style="`width: ${width}; padding-top: ${height};`"
    >
      <div class="default-icon"></div>
    </div>
  </div>
</template>

<script>
const [STATE_INITIAL, STATE_LOADING, STATE_FINISH, STATE_ERROR] = [
  "initial",
  "loading",
  "finish",
  "error",
];
export default {
  name: "LazyLoadImg",
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "60%",
    },
    display: {
      type: String,
      default: 'block'
    }
  },
  data() {
    return {
      imgState: STATE_INITIAL,
      miniImgState: STATE_INITIAL
    };
  },
  computed: {
    miniImgSrc() {
      return addSuffixToImgName(this.src, '-min');
    },
    webpImgSrc() {
      return this.src.replace(/\.(jpe?g|png|gif|svg)$/, '.webp');
    },
  },
  created() {
    this.loadMiniImg();
  },
  mounted() {
    const io = new IntersectionObserver(this.ioCb);

    io.observe(this.$refs.container);
  },
  methods: {
    loadMiniImg() {
      if (this.miniImgSrc && this.miniImgState === STATE_INITIAL) {
        this.miniImgState = STATE_LOADING;

        const img = document.createElement('img');

        img.className = 'mini-img';
        img.onload = () => {
          this.miniImgState = STATE_FINISH;

          if (this.imgState !== STATE_FINISH) {
            this.$refs.container.appendChild(img);
          }
          this.$refs.container.appendChild(img);
        };

        img.onerror = (err) => {
          this.miniImgState = STATE_ERROR;
        };
        img.src = this.miniImgSrc;
      }
    },
    loadImg() {
      if (this.src && this.imgState === STATE_INITIAL) {
        const img = document.createElement('img');

        img.onload = () => {
          this.imgState = STATE_FINISH;
          if (this.miniImgState === STATE_FINISH) {
            const miniImg = [...this.$refs.container.children].find(i => i.tagName === 'IMG');

            if (miniImg) {
              this.$refs.container.removeChild(miniImg);
            }
          }
          this.$refs.container.appendChild(img);
        };

        img.onerror = () => {
          this.imgState = STATE_ERROR;
        };

        // img.src = this.$isSupportWebp ? this.webpImgSrc : this.src;
        img.src = this.src;
      }
    },
    ioCb(entries) {
      const entry = entries[0];

      if (
        entry &&
        entry.intersectionRatio > 0
      ) {
        this.loadImg();
      }
    }
  },
};

function addSuffixToImgName(url, suffix) {
  const RE = /^(.*)\.(jpe?g|png|gif|svg)(.*)?/;
  const snippets = RE.exec(url);

  if (snippets) {
    return `${snippets[1] + (suffix || '')}.${snippets[2]}${snippets[3] || ''}`;
  } else {
    return;
  }
}

</script>

<style lang="scss">
.m-lazy-load-img {
  text-align: center;
  text-indent: 0;
  overflow: hidden;
  .default-img {
    position: relative;
    display: inline-block;
    //background-image: url("../../assets/loading.gif");
    background-color: #050505;
    //background-position: center;
    //background-repeat: no-repeat;
    //background-size: 4rem 4rem;
    //.default-icon {
    //  position: absolute;
    //  left: calc(50% - 2rem);
    //  top: calc(50% - 1rem);
    //  width: 4rem;
    //  height: 2rem;
    //  &::before,
    //  &::after {
    //    display: block;
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    width: 2rem;
    //    height: 2rem;
    //    border-radius: 50%;
    //  }
    //  &::before {
    //    left: 0;
    //    background-color: rgba(241, 104, 34, 1);
    //    animation: loading-left-ball-animation 1s linear infinite reverse;
    //  }
    //  &::after {
    //    left: 2rem;
    //    background-color: rgba(249, 140, 23, 1);
    //    animation: loading-right-ball-animation 1s linear infinite reverse;
    //  }
    //}
  }
  .mini-img {
    filter: blur(2px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=2, MakeShadow=false);
    overflow: hidden;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@keyframes loading-left-ball-animation {
  0% {
    left: 0;
    z-index: 1;
  }
  50% {
    left: 2rem;
    z-index: 1;
  }
  51% {
    left: 2rem;
    z-index: 0;
  }
  100% {
    left: 0;
    z-index: 0;
  }
}
@keyframes loading-right-ball-animation {
  0% {
    left: 2rem;
    z-index: 0;
  }
  50% {
    left: 0;
    z-index: 0;
  }
  51% {
    left: 0;
    z-index: 1;
  }
  100% {
    left: 2rem;
    z-index: 1;
  }
}
</style>
