export const consult = [
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/9.png',
    title: 'consult.services',
    introduce: 'consultIntroduce.services'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/14.png',
    title: 'consult.securityTeam',
    introduce: 'consultIntroduce.securityTeam'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/11.png',
    title: 'consult.experience',
    introduce: 'consultIntroduce.experience'
  },
  {
    icon: require('../../../assets/icon/15.png'),
    title: 'consult.perfect',
    introduce: 'consultIntroduce.perfect'
  }

]
