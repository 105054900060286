import Vue from 'vue';
import VueRouter from 'vue-router';
import relativeRoutes from './relativeRoutes';
import Layout from '@/layout';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    component: Layout,
    children: relativeRoutes
  },
  {
    path: '/zh-Hans-CN/',
    component: Layout,
    children: relativeRoutes
  },
  {
    path: '/ja/',
    component: Layout,
    children: relativeRoutes
  },
  {
    path: '/ko/',
    component: Layout,
    children: relativeRoutes
  },
  {
    path: '/hi/',
    component: Layout,
    children: relativeRoutes
  },
];

const router = new VueRouter({
  // base: process.env.NODE_ENV === 'production' ? '/easygoph/' : '/',
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
    window.document.documentElement.scrollTop = 0;
    next();
});

export default router;