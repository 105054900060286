import { languages } from '@/config/language';
import router from "@/router";

export function getRootPath() {
  return window.location.pathname.replace(router.options.base || '/', '').split('/')[0];
}

export function getLanguagePath() {
  const rootPath = getRootPath();
  const language = languages.find(language => language.value === rootPath);

  return language ? `/${language.value}/` : '/';
}

export function getLanguageRoutePath(routePath) {
  return getLanguagePath() + routePath;
}

export function getScrollBox() {
  return window;
}

export function cutArray(array, snippetLength) {
  if (!(array && array.length) && !snippetLength) return;

  const lineCount = Math.ceil(array.length / snippetLength);
  const list = [];

  for(let i = 0; i < lineCount; i++) {
    const startIndex = i * snippetLength;

    list.push(array.slice(startIndex, startIndex + snippetLength));
  }

  return list;
}

export function throttle(fn, interval) {
  let start = 0;

  return function (...args) {
    const now = Date.now();

    if (now > start + interval) {
      start = now;

      return fn.apply(this, args);
    }
  };
}

export function debounce(fn, interval) {
  let timer = null;

  return function (...args) {
    if (timer) clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(this, args);
    }, interval);
  };
}

export function getType(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1);
}

export function isType(data, expectedType) {
  let actualType = getType(data);

  return expectedType === actualType;
}


export function cloneDeep(data, map = new Map()) {
  if (['Object', 'Array'].includes(getType(data))) {
    const target = isType(data, 'Object') ? {} : [];

    if (map.get(data)) {
      return map.get(data);
    }

    map.set(data, target);

    const keys = Object.keys(data);

    while (keys.length) {
      const key = keys.shift();

      target[key] = cloneDeep(data[key], map);
    }

    return target;
  } else {
    return data;
  }
}

export function obj2qs(obj) {
  if (isType(obj, 'Object')) {
    return '?' + Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');
  }
}

export function qs2obj(qs) {
  if (isType(qs, 'String')) {
    qs = qs[0] === '?' ? qs.slice(1) : qs;

    const pairs = qs.split('&');

    return pairs.reduce((obj, pair) => {
      pair = pair.split('=');

      obj[pair[0]] = pair[1];
      return obj;
    }, {});
  }
}
