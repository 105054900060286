export default {
  data() {
    return {
      animated: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      const io = new IntersectionObserver(this.ioCb);
      io.observe(this.$refs.container);
    });
  },
  
  methods: {
    ioCb(entries) {
      const entry = entries[0];

      if (
        entry &&
        entry.intersectionRatio > 0
      ) {
        this.animated = true;
        // this.loadImg();
      }
    }
  }
}
