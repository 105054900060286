export const services = [
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/1.png',
    title: 'services.personalProtection'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/3.png',
    title: 'services.familyBodyguards'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/5.png',
    title: 'services.privateBodyguards'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/6.png',
    title: 'services.crisisManagement'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/8.png',
    title: 'services.assetProtection'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/7.png',
    title: 'services.communicationEncryption'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/4.png',
    title: 'services.bodyguardTraining'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2.png',
    title: 'services.armedEscorting'
  },
]
