<template>
  <div class="m-header-main">
    <div class="logo">
      <img class="logo1" src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo.png" alt="logo">
      <img class="logo2" :src="getLogoUrl()" alt="logo" :class="{ 'chinese-locale': isChineseLocale }">
    </div>
    <div class="other">
      <div class="contact">
        <div class="contact-ct">
          <p class="title">{{ contactTitle }}</p>
          <p class="phone">(+63) 917 180 6668</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRootPath } from "@/utils";
import { languages } from "@/config/language";

export default {
  computed: {
    isChineseLocale(){
      return this.$i18n.locale === 'zh-Hans-CN';
    },
    contactTitle() {
      return this.$t('other.contact');
    },
    mainTitle() {
      if (this.$isEnglish) {
        return this.$t('other.headerTitle', 'en-US');
      } else {
        return this.$t('other.headerTitle', 'zh-Hans-CN');
      }
    },
    subTitle() {
      if (!this.$isEnglish) {
        return this.$t('other.headerTitle', 'en-US');
      } else {
        return this.$t('other.headerTitle', 'zh-Hans-CN');
      }
    }
  },
  methods: {
    getLogoUrl() {
      const logoUrls = {
        'en-US': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/e.png',
        'zh-Hans-CN': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/zh.png',
        'ja': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/ja.png',
        'ko': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/ko.png',
        'hi': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/hi.png'
      };
      return logoUrls[this.$i18n.locale] || '';
    },
    // getLogoUrl(){
    //   if (this.$i18n.locale === 'en-US') {
    //     return 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/e.png';
    //   } else if (this.$i18n.locale === 'zh-Hans-CN') {
    //     return 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/zh.png';
    //   } else if (this.$i18n.locale === 'ja') {
    //     return 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/ja.png';
    //   }else if (this.$i18n.locale === 'ko') {
    //     return 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/ko.png';
    //   }else if (this.$i18n.locale === 'hi') {
    //     return 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/hi.png';
    //   }
    // },
    selectLanguage(languageValue) {
      const rootPath = getRootPath();
      let routePath, languagePath;

      const currLanguage = languages.find(language => language.value === rootPath);

      if (rootPath === languageValue || (!currLanguage && languageValue === languages[0].value)) return;

      if (currLanguage) {
        routePath = this.$route.fullPath.split('/').slice(2).join('/');
      } else {
        routePath = this.$route.fullPath;
      }

      if (languageValue === languages[0].value) {
        languagePath = '';
      } else {
        languagePath = languageValue;
      }

      window.location.href = window.location.origin + (this.$router.options.base || '/') + languagePath + routePath;
    },
  }
}
</script>

<style lang="scss">
$headerHeight: 12rem;
.m-header-main {
  position: relative;
  padding: 1rem 0;
  height: $headerHeight;
  .logo {
    margin-top: 1.25rem;
    display: inline-block;
    vertical-align: top;
    height: $headerHeight;
    line-height: $headerHeight;
    .logo1 {
      width: 7rem;
      filter: drop-shadow(-4px 4px 4px black);
    }
    .logo2 {
      padding: 1.4rem;
      height: 7rem;
      filter: drop-shadow(-4px 4px 4px black);
      }
      .chinese-locale {
        padding: 0rem;
      }
  }
  .header-title {
    margin-left: 2rem;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    line-height: $headerHeight;
    .title-ct {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.4rem;
      .title-main {
        font-size: 2.6rem;
        line-height: 3rem;
      }
      .title-sub {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }
    }
  }
  .other {
    position: absolute;
    right: 0;
    top: 0;
    .languages {
      display: inline-block;
      margin-left: 5rem;
      vertical-align: top;
      margin-top: 3rem;
      .languages-ct {
        p,
        span {
          display: inline-block;
          vertical-align: middle;
          padding: 2px;
          font-size: 1.6rem;
          font-weight: bold;
          font-style: italic;
        }
        .language {
          color: rgb(255, 175, 1);
          cursor: pointer;
        }
        .language__active {
          color: #888;
        }
        span {
          &.divider {
            margin: 0 .5rem;
            transform: rotate(15deg);
            &::before{
              display: block;
              content: '';
              width: 2px;
              height: 1.6rem;
              background-color: #eee;
            }
          }
        }
      }
    }
    .contact {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      line-height: $headerHeight;
      color: #bbb;
      .contact-ct {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.4rem;
        .title {
          font-size: 1.8rem;
          text-align: right;
          line-height: 2rem;
          text-shadow:2px 2px 2px #111;
        }
        .phone {
          font-size: 3rem;
          text-align: right;
          line-height: 4rem;
          text-shadow:2px 2px 2px #111;
        }
      }
    }
  }
}
.lang_en {
  .m-header-main {
    .title-sub {
      display: none;
    }
    .contact {
      .contact-ct {
        .title {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .m-header-main {
    .other {
      .languages {
        margin-left: 2rem;
      }
    }
  }
  .lang_en {
    .m-header-main {
      .contact {
        display: none;
      }
    }
  }
}
</style>
