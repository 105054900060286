<template>
  <div class="m-app-align">
    <div class="app-align-container">
      <div class="app-align-content">
        <div
          v-for="(button, index) in buttons"
          :key="index"
          class="align-button"
          :button="button"
        >
          <!-- <div v-for="(button, index) in buttons" :key="index" class="align-button" @click="operate(button.action)"> -->
          <div class="icon">
            <a :href="button.href"><img :src="button.icon" alt="" /></a>
          </div>
          <div class="title">{{ button.name }}</div>
        </div>
        <div class="align-button" @click="emailFn">
          <div class="icon">
            <a href=""
              ><img
                style="width: 4.5rem"
                src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/E-mail.svg"
                alt=""
            /></a>
          </div>
          <div class="title">{{ $t('other.email') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buttons } from './align';
export default {
  data() {
    return {
      buttons: buttons.map((button) => ({
        name: this.$t(button.name),
        href: button.href,
        icon: button.icon,
      })),
    };
  },
  computed: {
    baseinfo() {
      return this.$store.state.baseinfo;
    },
  },
  methods: {
    emailFn() {
      const recipient = 'security@365theone.com';
      const subject = '';
      const body = '';
      const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoUrl;
    },
    // operate(action) {
    //   if (action === 'telephone') {
    //     this.$store.mutations.switchTelephonePageState();
    //   } else if (action === 'wechat') {
    //     this.$store.mutations.switchQrCodePageState();
    //   } else if (action === 'like') {
    //     return;
    //   }
    // }
  },
};
</script>

<style lang="scss">
.m-app-align {
  position: fixed;
  right: 2rem;
  top: 30%;
  z-index: 10;
  .app-align-container {
    .app-align-content {
      .align-button {
        padding: 0;
        margin-bottom: 10px;
        border-radius: 20%;
        background-color: #333333;
        box-shadow: -2px 2px 6px rgba(0, 0, 0, 1);
        text-align: center;
        color: #999;
        font-size: 0;
        cursor: pointer;
        .icon {
          margin: 0;
          padding: 1rem;
          font-size: 40px;
          line-height: 40px;
          color: rgb(255, 175, 1);
          //img {
          //  width: 100%;
          //}
        }
        .title {
          padding: 0 4px 1rem;
          font-size: 1rem;
          color: #ebc83e;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:not(:first-of-type) {
          margin-top: 0.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .m-app-align {
    display: none;
  }
}
</style>
