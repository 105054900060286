<template>
  <div class="p-map">
    <div class="map-introduce">
        <p class="about-title">{{ $t('about.relation') }}</p>
        <p><i class="el-icon-phone"></i> {{ $t('other.phone') }}:  +63 917 180 6668</p>
        <p><i class="el-icon-location-outline"></i>  {{ $t('about.site') }}: {{ $t('about.address')}}</p>
        <p><i class="el-icon-message"></i>  {{ $t('about.mailbox') }}：security@365theone.com</p>
    </div>
    <div class="map">
      <GmapMap class="GmapMap" :center="center" :zoom="zoom">
        <GmapMarker :position="markerPosition" @click="openInfoWindow">
          <GmapInfoWindow :position="markerPosition" :opened="infoWindowOpened" @closeclick="closeInfoWindow">
            <div class="company-address">
              <p class="company"><strong>{{ company }}</strong></p>
              <img class="img" :src="imageUrl" alt="Location Image">
              <p class="address"><strong>Address：</strong>{{ address }}</p>
            </div>
          </GmapInfoWindow>
        </GmapMarker>
      </GmapMap>
    </div>
  </div>
  
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

export default {
  
  data() {
    return {
      center: { lat: 14.558394199252726, lng: 121.01352178591135 },
      zoom: 15,
      markerPosition: { lat: 14.5583941992527266, lng: 121.01352178591135 },
      infoWindowOpened: false,
      company: '365 Security Agency Company',
      imageUrl: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/address.JPG',
      address: 'G/F Kings Court 2 Bldg 2129 Chino Roces Ave Legazpi Village Makati City Philippines',
      city: '',
      country: '',
    };
  },

  methods: {
    openInfoWindow() {
      this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
    },
  },
  mounted() {
    // gmapApi().then(() => {
    //   this.infoWindowOpened = true;
    // });
    }
};
</script>

<style lang="scss">
.p-map {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .map-introduce {
    padding: 2rem;
    width: 100%;
    border-radius: 2rem;
    p {
      font-size: 1.5rem;
      color: #fff;
      line-height: 3rem;
    }
  }
  .map {
    width: 100%;
    .GmapMap {
      width: 100%;
      height: 75rem;
      .company-address {
        width: 40rem;
        height: 35rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .company {
          font-size: 2.2rem;
        }
        .img {
          width: 30rem;
        }
        .address {
          padding:  0 3rem;
          font-size: 1.5rem;
          
        }
        
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .p-map {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .map-introduce {
      padding: 2rem;
      width: 100%;
      border-radius: 2rem;
      p {
        font-size: 1.5rem;
        color: #fff;
        line-height: 3rem;
      }
    }
    .map {
      width: 100%;
      .GmapMap {
        width: 105%;
        height: 55rem;
        .company-address {
          width: 100%;
          height: 26rem;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .company {
            font-size: 1.6rem;
          }
          .img {
            width: 20rem;
          }
          .address {
            padding:  0 1rem;
            font-size: 1.4rem;
            
          }
          
        }
      }
    }

  }
}
</style>