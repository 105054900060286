export default {
  init(Vue) {
    const requiredComponents = require.context('./', true, /index\.vue$/);

    requiredComponents.keys().forEach(filepath => {
      let component = requiredComponents(filepath);

      component = component.default || component;

      Vue.component(component.name, component);
    });
  }
};
