<template>
  <div>
    
  </div>
  
</template>

<script>
export default {
  methods: {
    
  }
}
</script>

<style lang="scss">
.m-pc-im {
  position: fixed;
  right: 2.5rem;
  top: 35%;
  z-index: 12;
  img {
    cursor: pointer;
  }
}
@media screen and (max-width: 767px) {
  .m-app-im {
    display: none;
  }
}

</style>
