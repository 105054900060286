<template>
  <div class="app-sidebar" @touchmove.prevent>
    <div class="sidebar-container">
      <div class="sidebar-content">
        <div
          v-for="item in menu"
          :key="item.key"
          class="menu-item"
          :class="{ 'menu-item__activated': item.sectionValue === activatedSectionValue }"
          @click="_toRoute(item)"
        >
          {{ $t(item.name) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuActions from "@/mixins/menuActions";

export default {
  mixins: [menuActions],
  data() {
    return {
      activatedSectionValue: "",
    };
  },
  computed: {
    menu() {
      return this.$store.state.pageMenu;
    }
  },
  mounted() {
    this.$store.actions.addSectionChangeListener(this.activeMenuItem);
    this.$on("hook:beforeDestroy", () => {
      this.$store.actions.removeSectionChangeListener(this.activeMenuItem);
    });
  },
  methods: {
    activeMenuItem(sectionName) {
      const item = this.menu.find((item) => item.sectionValue === sectionName);
      if (item) {
        this.activatedSectionValue = item.sectionValue;
      } else {
        this.activatedSectionValue = "";
      }
    },
    _toRoute(item) {
      this.toRoute(item);
      this.$store.mutations.setMobileSideBarState(false)
    }
  },
};
</script>

<style lang="scss">
.app-sidebar {
  height: 100%;
  .sidebar-container {
    position: relative;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    .sidebar-content {
      position: absolute;
      right: 0;
      top: 0;
      width: 17rem;
      height: 100%;
      padding: 1.3rem 0;
      background-color: #1b1b1b;
      border-top: 1px solid rgba(0, 0, 0, .2);
      box-shadow: -4px 4px 10px rgba(0, 0, 0, .4);
      color: #bbb;
      .menu-item {
        padding: 1rem 1rem;
        font-size: 1.4rem;
        &.menu-item__activated {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
