<template>
  <div class="m-header-menu">
    <div class="menu-container">
      <span
        v-for="item in menu"
        :key="item.key"
        class="menu-item"
        :class="{ 'menu-item__activated': item.sectionValue === activatedSectionValue }"
        @click="toRoute(item)"
        >{{ $t(item.name) }}</span
      >
    </div>
  </div>
</template>

<script>
import menuActions from '@/mixins/menuActions';

export default {
  mixins: [menuActions],
  data() {
    return {
      activatedSectionValue: "",
    };
  },
  computed: {
    menu() {
      return this.$store.state.pageMenu;
    }
  },
  mounted() {
    this.$store.actions.addSectionChangeListener(this.activeMenuItem);
    this.$on("hook:beforeDestroy", () => {
      this.$store.actions.removeSectionChangeListener(this.activeMenuItem);
    });
  },
  methods: {
    activeMenuItem(sectionName) {
      const item = this.menu.find((item) => item.sectionValue === sectionName);
      if (item) {
        this.activatedSectionValue = item.sectionValue;
      } else {
        this.activatedSectionValue = "";
      }
    },
  },
};
</script>

<style lang="scss">
$barHeight: 46px;
.m-header-menu {
  height: $barHeight;
  background-color: #090909;
  color: #bbb;
  .menu-container {
    text-align: center;
    .menu-item,
    .menu-item__activated {
      display: inline-block;
      padding: 1.1rem 5rem;
      border-radius: 5px;
      font-size: 1.8rem;
    }
    .menu-item {
      position: relative;
      cursor: pointer;
      &:not(:first-of-type)::before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 1.3rem;
        height: 1.5rem;
        width: 2px;
        background-color: rgba(255, 255, 255, .05);
        //background-color: rgba(0, 0, 0, .5);
      }
    }
    .menu-item__activated {
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 1024px) {
  .m-header-menu {
    .menu-container {
      .menu-item,
      .menu-item__activated {
        padding: 1rem 2.6rem;
        font-size: 1.5rem;
        &:not(:first-of-type) {
        }
      }
    }
  }
}
</style>
