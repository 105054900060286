<template>
  <div class="m-footer">
    <Copyright></Copyright>
  </div>
</template>

<script>
import Copyright from './Copyright';

export default {
  components: { Copyright },
};
</script>

<style lang="scss">
.m-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #111;
}
@media screen and (max-width: 767px) {
  .m-footer {
    bottom: 8rem;
  }
}
</style>
