import request from '@/utils/request'

// 获取新闻列表
export function getNews() {
  return request({
    url: '/news/getNews',
    method: 'post'
  })
}
export function getNew(data) {
  return request({
    url: '/news/getNew',
    method: 'post',
    data
  })
}

