<template>
  <div class="p-information">
    <div class="information-title">
      <span class="information">{{ $t('menu.information') }}</span>
      <span class="location">{{ $t('other.location') }}：{{ $t('other.homepage') }} > {{ $t('menu.information') }}</span>
    </div>
    <div class="information-content">
      <div class="information-content-ctnr">
        <div class="content-title">{{ getLocalizedTitle(newsDetails) }}</div>
        <div class="content-message">
          <span>{{ $t('successfulCase.time') }}：{{ newsDetails.createTime }}</span>  
          <span class="divider"></span>
          <!-- 阅读量 -->
          <span>{{$t('other.pv')}}: {{clickCount + newsDetails.fpv }}</span>
        </div>
        <div class="content">
          <p class="description">{{ getLocalizedDescription(newsDetails) }}</p>
          <!-- <img class="image" :src="newsDetails.cover" alt=""> -->
          <p class="content-content" v-html="getLocalizedContent(newsDetails)"></p>
        </div> 
      </div>
    </div>
    <About></About>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getNew } from '@/api/news';
import About from '../home/About';

export default {
  components: { About },
  data() {
    return {
      newsDetails: {},
      clickCount: 0
    };
  },
  created() {
    const id = this.$route.query.id;
    let clickCount = localStorage.getItem('clickCount'); 
    clickCount = clickCount ? parseInt(clickCount) : 0; 
    // console.log(45, id);
    // console.log(46, clickCount); 
    this.clickCount = clickCount; 
    this.getNewsDetails({ id });
  },
  methods: {
    async getNewsDetails(id) {
      const res = await getNew(id);
      // console.log(46, res.data);
      this.newsDetails = res.data.data;
      this.newsDetails.createTime = dayjs(this.newsDetails.createTime).format('YYYY-MM-DD');
    },
    getLocalizedTitle(newsDetails) {
      if (this.$i18n.locale === 'en-US') {
        return newsDetails.title;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return newsDetails.ctitle;
      } else if (this.$i18n.locale === 'ja') {
        return newsDetails.jtitle;
      }else if (this.$i18n.locale === 'ko') {
        return newsDetails.ktitle;
      }else if (this.$i18n.locale === 'hi') {
        return newsDetails.ititle;
      }
    },
    getLocalizedDescription(newsDetails) {
      if (this.$i18n.locale === 'en-US') {
        return newsDetails.description;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return newsDetails.cdescription;
      } else if (this.$i18n.locale === 'ja') {
        return newsDetails.jdescription;
      }else if (this.$i18n.locale === 'ko') {
        return newsDetails.kdescription;
      }else if (this.$i18n.locale === 'hi') {
        return newsDetails.idescription;
      }
    },
    getLocalizedContent(newsDetails) {
      if (this.$i18n.locale === 'en-US') {
        return newsDetails.content;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return newsDetails.ccontent;
      } else if (this.$i18n.locale === 'ja') {
        return newsDetails.jcontent;
      }else if (this.$i18n.locale === 'ko') {
        return newsDetails.kcontent;
      }else if (this.$i18n.locale === 'hi') {
        return newsDetails.icontent;
      }
    }
  }
};
</script>
<style lang="scss">
.p-information {
  margin-top: 20.5rem;
  background-color: #1b1b1b;
  .information-title {
    max-width: 115rem;
    margin: 0 auto;
    height: 6rem;
    background-color: #1b1b1b;
    .information {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 6rem;
      float: left;
    }
    .location {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 6rem;
      float:right;
    }
  }
  .information-content {
    margin: 0 auto;
    padding: 6rem 0 10rem;
    // height: 70rem;
    background-color: #111111;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/15.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .information-content-ctnr {
      max-width: 100rem;
      margin: auto;
      .content-title {
        color: #fff;
        font-size: 2.5rem;
        text-align: center;
      }
      .content-message {
        max-width: 60rem;
        margin: auto;
        // display: flex;
        // justify-content: space-around;
        color: #fff;
        font-size: 1.4rem;
        padding: 2rem 0;
        text-align: center;
        .divider {
          margin: 0 2rem;
          border: 1px solid #fff;
        }
        .author {
          margin-right: 5rem;
          display: inline-block;
        }
      }
      .content {
        overflow: auto;
        .image {
          width: 50%;
          display: block;
          margin: 1.5rem 0;
          margin-left: auto;
          margin-right: auto;
        }
        p {
          color: #ccc;
          line-height: 3.5rem;
          // text-indent: 2em;
          font-size: 1.6rem;
          text-align: justify;
        }
        .description {
          text-indent: 2em;
        }
      }
    }
    
  }

  
}

@media screen and (max-width: 767px) {
  .p-information {
    margin-top: 0;
    background-color: #1b1b1b;
    .information-title {
      max-width: 35rem;
      margin: 0 auto;
      height: 5rem;
      background-color: #1b1b1b;
      .information {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 6rem;
        float: left;
      }
      .location {
        color: #fff;
        font-size: 1.3rem;
        font-weight: 400;
        float:right;
      }
    }
    .information-content {
      margin: 0 auto;
      padding: 3rem 0 5rem;
      .information-content-ctnr {
        max-width: 35rem;
        margin: auto;
        .content-title {
          color: #fff;
          font-size: 1.6rem;
          text-align: center;
        }
        .content-message {
          max-width: 35rem;
          margin: auto;
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-evenly;
          color: #fff;
          font-size: 1rem;
          padding: 1.6rem 0;
          text-align: center;
          .author {
            margin: 0 2rem;
            display: inline-block;
          }
        
        }
        .content {
          overflow: auto;
          .image {
            width: 100%;
            display: block;
            margin: 1.5rem 0;
            margin-left: auto;
            margin-right: auto;
          }
          p {
            color: #ccc;
            line-height: 3.5rem;
            // text-indent: 2em;
            font-size: 1.4rem;
            text-align: justify;
          }
          .content-content {
            img {
              max-width: auto;
              height: auto;
            }
          }
        }
      }
      
    }

    
  }
    
}
</style>

