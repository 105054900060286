const titleTrail = '365保安|保镖安全顾问公司官方网站'
const titleTrailEn = 'The Official Website Of 365 Security|Bodyguard Security Consulting';
const titleTrailJa = '365保安|ボディーガード安全コンサルタント会社の公式サイト';
const titleTrailKo = '365 보안 | 경호원 국가안보 보좌관이 회사 홈페이지'
const titleTrailHi = '365 सुरक्षा। अंगरक्षक सुरक्षा परामर्श कंपनी की आधिकारिक वेबसाइट'

const commonKeywords = '私人保镖,家庭保镖,个人保护,危机处理,通讯加密,保镖培训,资产保护,武装押运';
const commonKeywordsEn = 'Personal Protection,Private Bodyguards,Family Bodyguards,Crisis Management,Communication Encryption,Bodyguard Training,Asset Protection,Armed Escorting';
const commonKeywordsJa = '個人の護衛、家族の護衛、個人保護、危機管理、暗号通信、護衛訓練、資産保護、武装護送';
const commonKeywordsKo = '개인경호, 가정경호, 개인보호, 위기처리, 통신암호화, 경호훈련, 자산보호, 무장호송을 진행한다';
const commonKeywordsHi = 'निजी अंगरक्षक, परिवार अंगरक्षक, व्यक्तिगत सुरक्षा, संकट प्रबंधन, संचार एन्क्रिप्शन, अंगरक्षक प्रशिक्षण, संपत्ति सुरक्षा, सशस्त्र एस्कॉर्ट';

const commonDescription = '365保安|保镖安全顾问公司作为业内首屈一指的专业公司，成功打造立足于客户人身和财产安全的“追求 卓越，专业高端”的品牌形象。365公司总部设立于菲律宾。旗下所有人员都是由公司亲自挑选，并经过基地的系统培训、人品的层层筛选，最终派遣服务客户。'
const commonDescriptionEn = '365 Security|Bodyguard Security Consulting Company is the industry\'s leading professional company, based on the protection of customers\' personal and property safety. The company is headquartered in the Philippines. All the staff under the company are carefully selected by the company, and after the base’s systematic training and character selection, they are finally dispatched to serve customers.';
const commonDescriptionJa = '365保安|ボディーガード安全顧問会社として业界屈指の専門会社、人命と財産の安全に成功を顧客に即し「卓越さを追求し、専攻ハイエンド」のブランドイメージ。365はフィリピンに本社があります。すべてのスタッフは会社が自ら選び、基地での体系的な訓練、人柄などを経て、最終的にお客様に派遣します。';
const commonDescriptionKo = '365 보안 | 경호원 국가안보 보좌관이 회사는 업계 최고의 전문 회사 로서 성공을 만들어 고객의 인신 안전과 재산 안전에 입각 한 추구 탁월 하여 전문 고급 브랜드 이미지다.365회사는 필리핀에 본사를 두고 있다.산하에 있는 모든 직원을 회사가 직접 뽑고, 기지 차원의 체계적인 교육과 인품 선별을 거쳐 최종적으로 서비스 고객을 파견한다.'
const commonDescriptionHi = "365 सुरक्षा। अंगरक्षक सुरक्षा परामर्श कंपनी, जो उद्योग की अग्रणी व्यावसायिक कंपनी है, ने ग्राहकों की व्यक्तिगत और संपत्ति सुरक्षा पर आधारित 'उत्कृष्ट, व्यावसायिक उच्च अंत' ब्रांड छवि का सफलतापूर्वक सृजन किया है।365 का मुख्यालय फिलीपींस में हैइसके सभी कार्मिकों का चयन कंपनी द्वारा और आधार के व्यवस्थित प्रशिक्षण के माध्यम से किया जाता है जो स्क्रीनिंग की परतों का स्वरूप होता है और अंततः ग्राहकों की सेवा के लिए प्रेषित होता है।"


const pageInfo = [
  {
    paths: ['/zh-Hans-CN/'],
    title: '365保安|保镖安全顾问' + '-' + titleTrail,
    keywords: commonKeywords,
    descriptions: commonDescription
  },
  {
    paths: ['/'],
    title: '365 Bodyguard | Security Consulting' + '-' + titleTrailEn,
    keywords: commonKeywordsEn,
    descriptions: commonDescriptionEn
  },
  {
    paths: ['/ja/'],
    title: '365保安|ボディーガード安全顧問' + '-' + titleTrailJa,
    keywords: commonKeywordsJa,
    descriptions: commonDescriptionJa
  },
  {
    paths: ['/ko/'],
    title: '365 보안 | 경호원 국가안보 보좌관' + '-' + titleTrailKo,
    keywords: commonKeywordsKo,
    descriptions: commonDescriptionKo
  },
  {
    paths: ['/hi/'],
    title: '365 सुरक्षा | अंगरक्षक सुरक्षा सलाहकार' + '-' + titleTrailHi,
    keywords: commonKeywordsHi,
    descriptions: commonDescriptionHi
  },
  {
    paths: ['/zh-Hans-CN/AboutUs'],
    title: '关于我们' + '-' + titleTrail,
    keywords: commonKeywords,
    descriptions: commonDescription
  },
  {
    paths: ['/AboutUs'],
    title: 'About US' + '-' + titleTrailEn,
    keywords: commonKeywordsEn,
    descriptions: commonDescriptionEn
  },
  {
    paths: ['/ja/AboutUs'],
    title: '僕らについて' + '-' + titleTrailJa,
    keywords: commonKeywordsJa,
    descriptions: commonDescriptionJa
  },
  {
    paths: ['/ko/AboutUs'],
    title: '회사소개' + '-' + titleTrailKo,
    keywords: commonKeywordsKo,
    descriptions: commonDescriptionKo
  },
  {
    paths: ['/hi/AboutUs'],
    title: 'हमारे बारे में' + '-' + titleTrailHi,
    keywords: commonKeywordsHi,
    descriptions: commonDescriptionHi
  },
]

export function setPageInfo() {
  const pathname = window.document.location.pathname;
  const info = pageInfo.find(info => info.paths.includes(pathname));
  const keywordsMeta = document.querySelector("meta[name=keywords]");
  const descriptionsMeta = document.querySelector("meta[name=description]");
  if (info) {
    window.document.title = info.title;
    keywordsMeta.content = info.keywords;
    descriptionsMeta.content = info.descriptions;
  }
}
