<template>
  <div class="m-home-section-header">
    <div class="header">{{ header }}</div>
    <div class="divider"></div>
    <div class="sub-header">{{ subHeader }}</div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    subHeader: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.m-home-section-header {
  color: #bbb;
  margin: 0 auto;
  text-align: center;
  .header {
    font-size: 4rem;
  }
  .divider {
    margin: 1.1rem auto;
    width: 5rem;
    height: 3px;
    background-color: rgba(235, 200, 62, 1);
    border-radius: 2px;
  }
  .sub-header {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1280px) {
  .m-home-section-header {
    .header {
      font-size: 3.6rem;
    }
    .sub-header {
      font-size: 2.6rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .m-home-section-header {
    .header {
      font-size: 2.4rem;
    }
    .sub-header {
      font-size: 1.8rem;
    }
  }
}
</style>
