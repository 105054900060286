<template>
  <div class="p-aboutUs">
    <div class="aboutUs-title">
      <span class="about">{{ $t('menu.about') }}</span>
      <span class="location">{{ $t('other.location') }}：{{ $t('other.homepage') }} > {{ $t('menu.about') }}</span>
    </div>
    <div class="cover" :style="{ backgroundImage: coverImage }"></div>
    <div class="company">
      <div class="company-header">
        <!-- <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/2.png" alt=""> -->
        <p>{{ $t('aboutUs.services') }}</p>
      </div>
      <div class="company-detail">
        <p>{{ $t('about.companyOne') }}</p>
        <p>{{ $t('about.companyTwo') }}</p>
      </div>
    </div>
    <!-- 服务宗旨 -->
    <div class="serve">
      <div class="serve-header">
        <!-- <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/3.png" alt=""> -->
        <p>{{ $t('aboutUs.serve') }}</p>
      </div>
      <div class="serve-detail">
        <p>{{ $t('about.serve') }}</p>
      </div>
    </div>
    <!-- 荣誉资质 -->
    <div class="honor">
      <div class="honor-header">
        <!-- <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/5.png" alt=""> -->
        <p>{{ $t('aboutUs.honor') }}</p>
      </div>
      <div class="honor-certificate">
        <div class="certificate">
          <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/9.png" alt="">
          <p>{{ $t('about.license') }}</p>
        </div>
        <div class="certificate">
          <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/10.png" alt="">
          <p>{{ $t('about.pasay') }}</p>
        </div>
      </div>
    </div>
    <!-- 联系方式 -->
    <div class="contact">
      <div class="contact-header">
        <!-- <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/7.png" alt=""> -->
        <p>{{ $t('aboutUs.contact') }}</p>
      </div>
      
      <div class="contact-address">
        <p class="address"><i class="el-icon-location-outline"></i> {{ $t('about.site') }}：{{ $t('about.address') }}</p>
        <div class="map">
        <GmapMap class="GmapMap" :center="center" :zoom="zoom">
          <GmapMarker :position="markerPosition" @click="openInfoWindow">
            <GmapInfoWindow :position="markerPosition" :opened="infoWindowOpened" @closeclick="closeInfoWindow">
              <div class="company-address">
                <span class="company-title"><strong>{{ company }}</strong></span>
                <img class="img" :src="imageUrl" alt="Location Image">
                <span class="address"><strong>Address：</strong>{{ address }}</span>
              </div>
            </GmapInfoWindow>
          </GmapMarker>
        </GmapMap>
      </div>
        <div class="contact-way">
          <div class="way">
            <p><i class="el-icon-message"></i>  {{ $t('about.email') }}</p>
            <p>{{ $t('about.mailbox') }}</p>
          </div>
          <div class="way">
            <p><i class="el-icon-phone"></i>  (+63) 917 180 6668</p>
            <p>{{ $t('about.relation') }}</p>
          </div>
          <div class="way">
            <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/qrcode.png" alt="">
            <p>{{ $t('about.WeChat') }}</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import scrollMixins from '@/mixins/scroll';
import sectionMixins from '@/mixins/section';
import { qs2obj } from "@/utils";
import SectionHeader from "@/views/home/common/SectionHeader";


export default {
  components: { SectionHeader, },
  mixins: [scrollMixins, sectionMixins],
  data() {
    return {
      sectionsData: [],
      scrollBox: null,
      scrollBoxEl: null,
      activeTab: 'instructions',
      activeComponent: 'Instructions',
      content: [],
      center: { lat: 14.558394199252726, lng: 121.01352178591135 },
      zoom: 15,
      markerPosition: { lat: 14.5583941992527266, lng: 121.01352178591135 },
      infoWindowOpened: false,
      company: '365 Security Agency Company',
      imageUrl: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/address.JPG',
      address: 'G/F Kings Court 2 Bldg 2129 Chino Roces Ave Legazpi Village Makati City Philippines',
      city: '',
      country: '',
    };
  },
  computed: {
    coverImage() {
      const languageUrls = {
        English: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/en.png',
        Chinese: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/zh.png',
        Japanese: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/ja.png',
        Korean: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/ko.png',
        India: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/hi.png',
      };

      const language = this.$isEnglish
        ? 'English'
        : this.$isChinese
          ? 'Chinese'
          : this.$isJapanese
            ? 'Japanese'
            : this.$isKorean
              ? 'Korean'
              : 'India';

      return `url("${languageUrls[language]}")`;
    }

    
  //   coverImage() {
  //     if (this.$isEnglish) {
  //       return 'url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/en.png")';
  //     } else if(this.$isChinese) {
  //       return 'url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/zh.png")';
  //     }else if(this.$isJapanese) {
  //       return 'url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/ja.png")';
  //     }else if(this.$isKorean) {
  //       return 'url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/ko.png")';
  //     }else {
  //       return 'url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/cover/hi.png")';
  //     }
  //   }
  },
  mounted() {
    this.setSectionData([
      {
        name: 'contact',
        el: this.$refs.contact
      }
    ]);

    setTimeout(() => {
      const search = window.location.search;

      if (search) {
        const pairs = qs2obj(search);

        if (pairs && pairs.section) {
          this.toSection(pairs.section);
        }
      }
    }, 0);
  },
  methods: {
    openInfoWindow() {
      this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
    },
    setCurrentSection(section) {
      this.$store.actions.setCurrentSection(section.name);
    },
    setSectionController(controller) {
      this.$store.mutations.setSectionController(controller);
    },
    switchTo(tab) {
      this.activeTab = tab.value;
      this.activeComponent = tab.component;
    }
  }
};
</script>

<style lang="scss">
.p-aboutUs {
  margin-top: 20.5rem;
  background-color: #1b1b1b;
  .aboutUs-title {
    max-width: 115rem;
    margin: 0 auto;
    height: 6rem;
    background-color: #1b1b1b;
    .about {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 6rem;
      float: left;
    }
    .location {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 6rem;
      float:right;
    }
  }
  .cover {
    // margin-top: 14rem;
    position: relative;
    height: 65rem;
    padding-top: 13rem;
    // background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/banner5.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .company {
    padding: 8rem 0;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .company-detail {
      color: #fff;
      max-width: 100rem;
      margin: 0 auto;
      p{
        margin-top: 4rem;
        font-size: 2rem;
        text-align: left;
        text-indent: 2em;
        line-height: 4rem;
      }
    }
  }
  .serve {
    padding: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/4.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .serve-detail {
      color: #fff;
      max-width: 100rem;
      margin: 0 auto;
      p{
        margin-top: 4rem;
        font-size: 2rem;
        text-align: left;
        text-indent: 2em;
        line-height: 4rem;
      }
    }
  }
  .honor {
    height: 85rem;
    padding-top: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/6.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    .honor-certificate {
      max-width: 100rem;
      margin: 0 auto;
      .certificate {
        float: left;
        padding: 8rem 10rem;
        img {
          width: 30rem;
        }
        p {
          color: #fff;
          font-size: 2rem;
          line-height: 6rem;
        }
        img:first-child {
          transform: translateX(-100%);
          animation: slide-in-left 3s forwards;
        }

        img:last-child {
          transform: translateX(100%);
          animation: slide-in-right 3s forwards;
        }

        @keyframes slide-in-left {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0);
          }
        }

        @keyframes slide-in-right {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0);
          }
        }
      }
    }
  }
  .contact {
    padding: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/8.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .contact-address {
      color: #fff;
      max-width: 100rem;
      margin: 0 auto;
      p{
        margin-top: 4rem;
        font-size: 2.2rem;
        text-align: center;
        line-height: 4rem;
      }
      .map {
        margin-top: 4rem;
        width: 100%;
        .GmapMap {
          width: 100%;
          height: 50rem;
          .company-address {
            width: 40rem;
            height: 32rem;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: #000;
            .company-title {
              font-size: 2.2rem;
              
            }
            .img {
              width: 30rem;
            }
            .address {
              padding:  0 1.5rem;
              font-size: 1.5rem;
              
            }
            
          }
        }
      }
      .contact-way {
        margin-top: 4rem;
        height: 20rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .way {
          p:nth-child(2) {
            margin-top: 1rem;
          }
          img {
            width: 16rem;
          }
        }
      }
    }
    
  }
  .company-header,
  .serve-header,
  .honor-header,
  .contact-header {
    p {
      font-size: 4rem;
      color: #ededed;
    }
  }


  
}

@media screen and (max-width: 767px) {
  .p-aboutUs {
    margin-top: 0;
    .aboutUs-title {
      display: none;
      // max-width: 35rem;
      // margin: 0 auto;
      // height: 5rem;
      // background-color: #1b1b1b;
      .about {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 6rem;
        float: left;
      }
      .location {
        color: #fff;
        font-size: 1.3rem;
        font-weight: 400;
        float:right;
      }
    }
    .cover {
      margin-top: 0rem;
      height: 20rem;
      padding-top: 7rem;
    }
    .about-ct-ctnr {
      padding: 2.6rem 2rem 0;
    }
    .company {
      // height: 45rem;
      padding: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      // .company-header {
      //   max-width: 35rem;
      //   margin: 0 auto;
      // }
      .company-detail {
        color: #fff;
        // max-width: 32rem;
        // padding: 0 2rem;
        margin: 0 auto;
        p{
          margin-top: 3rem;
          font-size: 1.3rem;
          text-align: left;
          text-indent: 2em;
          line-height: 2.5rem;
        }
        
      }
    }

    .serve {
      padding: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/4.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      // .serve-header {
      //   max-width: 10rem;
      //   margin: 0 auto;
      // }
      .serve-detail {
        color: #fff;
        margin: 0 auto;
        p{
          margin-top: 2rem;
          font-size: 1.3rem;
          text-align: left;
          text-indent: 2em;
          line-height: 2.5rem;
        }
      }
    }

    .honor {
      height: 80rem;
      padding: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/6.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      text-align: center;
      // .honor-header {
      //   max-width: 18rem;
      //   margin: 0 auto;
      // }
      .honor-certificate {
        margin: 0 auto;
        .certificate {
          padding: 2rem 6.5rem;
          img {
            width: 18rem;
          }
          p {
            font-size: 1.4rem;
            line-height: 3rem;
          }
        }
      }
    }
    .contact {
      padding: 4rem 0 6rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/8.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      // .contact-header {
      //   max-width: 16rem;
      //   margin: 0 auto;
      // }
      .contact-address {
        // padding: 0 4rem;
        margin-top: 2rem;
        p {
          padding: 0 4rem;
          font-size: 1.4rem;
          margin-top: 1rem;
          line-height: 3rem;
        }
        .map {
          display: none;
        }
        .contact-way {
          height: 40rem;
          margin-top: 1rem;
          max-width: 50rem;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          .way {
            p:nth-child(2){
              margin-top: 0rem;
            }
          }

        }
      }
    }
    .company-header,
    .serve-header,
    .honor-header,
    .contact-header {
      // padding: 0 1rem;
      p {
        font-size: 2.2rem;
        color: #ededed;
      }
    }
  }
  
}
</style>

