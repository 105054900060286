<template>
  <div ref="container" class="m-home-video">
    <div class="video-wrapper">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </div>
  </div>
</template>

<script>
// 以下三行一定要引入
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        controls: true, //控制条
        preload: 'auto', //视频预加载
        muted: false, //默认情况下将会消除任何音频。
        loop: true, //导致视频一结束就重新开始。
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/video.mp4',
          },
        ],
        // sources: [{
        //   // type: 'video/mp4',
        //   // src: require('@/assets/video.mp4')//你所放置的视频的地址，最好是放在服务器上
        // }],
        poster:
          'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/video_cover.png', //你的封面地址（覆盖在视频上面的图片）
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
    };
  },
  mounted() {
    const player = this.$refs.videoPlayer.player;

    player.on('ended', () => {
      console.log('视频播放结束');
    });
  },
};
</script>

<style lang="scss">
.m-home-video {
  width: 60rem;
  height: 100%;
  .video-wrapper {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .m-home-video {
    width: 37.5rem;
    height: 100%;
    .video-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
