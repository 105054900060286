<template>
  <div ref="container" class="m-home-success">
    <div class="success-title">
      <p>{{ $t('title.success') }}</p>
    </div>
    <div class="success-ctnr">
      <el-carousel :interval="4000" type="card">
        <el-carousel-item v-for="item in cases" :key="item.id">
          <img :src="item.cover" alt="" @click="toAbout(item.id)" />
          <p class="title" @click="toAbout(item.id)">
            {{ getLocalizedTitle(item) }}
          </p>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { getCases } from '@/api/case';

import animationMixin from '@/mixins/animations';

export default {
  mixins: [animationMixin],
  data() {
    return {
      cases: [],
      clickCount: 0,
    };
  },
  created() {
    this.getCaseList();
  },
  methods: {
    toAbout(id) {
      let clickCount = localStorage.getItem('clickCount');
      clickCount = clickCount ? parseInt(clickCount) + 1 : 1;
      localStorage.setItem('clickCount', clickCount);
      this.$router.push({
        path: '/SuccessfulCase',
        query: { id: id, clickCount: clickCount },
      });
    },
    async getCaseList() {
      const res = await getCases();
      this.cases = res.data.data;
    },
    getLocalizedTitle(item) {
      if (this.$isEnglish) {
        return item.title;
      } else if (this.$isChinese) {
        return item.ctitle;
      } else if (this.$isJapanese) {
        return item.jtitle;
      } else if (this.$isKorean) {
        return item.ktitle;
      } else if (this.$isIndia) {
        return item.ititle;
      }
    },
  },
};
</script>

<style lang="scss">
.m-home-success {
  margin: 0 auto;
  padding: 6rem 0 10rem;
  height: 80rem;
  background-color: #111111;
  background-image: url('https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/7.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .success-title {
    font-size: 4rem;
    color: #ededed;
    line-height: 10rem;
  }
  .success-ctnr {
    max-width: 110rem;
    margin: 5rem auto;
    .el-carousel {
      .el-carousel__container {
        height: 48rem;
      }
      .el-carousel__item {
        img {
          width: 48rem;
          height: 40rem;
        }
        p {
          color: #fff;
          padding: 1.5rem;
          font-size: 2.2rem;
        }
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #111;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #111;
      }
    }
    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
@media screen and (max-width: 767px) {
  .m-home-success {
    height: 40rem;
    padding: 4rem 0 6rem;
    .success-title {
      font-size: 2.2rem;
      color: #ededed;
      line-height: 5rem;
    }
    .success-ctnr {
      padding: 2rem;
      margin: 1rem auto;
      .el-carousel {
        .el-carousel__container {
          height: 20.5rem;
        }
        .el-carousel__item {
          width: 18rem;
          img {
            width: 18rem;
            height: 16rem;
          }
          P {
            color: #fff;
            padding: 1rem 0.3rem;
            font-size: 1.2rem;
          }
        }

        .el-carousel__item:nth-child(2n) {
          background-color: #111;
        }

        .el-carousel__item:nth-child(2n + 1) {
          background-color: #111;
        }
        .el-carousel__button {
          width: 8px;
        }
      }
    }
  }
}
</style>
