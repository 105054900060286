import axios from 'axios';

// create an axios instance
const service = axios.create({
  baseURL: 'https://365theone.com/api/www', // url = base url + request url
  // baseURL: 'http://192.168.1.110:7566/api/www', // url = base url + request url
  // baseURL: 'http://192.168.1.56:7566/api/www', // url = base url + request url
  timeout: 5000 
});

// request interceptor
service.interceptors.request.use(
  config => {

    return config;
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error);
  }
)

// response interceptor
// service.interceptors.response.use(
//   response => {
//     const res = response.data;

//     res.code = Number(res.code);

//     if (res.code !== 1000) {
//       return Promise.reject(new Error(res.message));
//     } else {
//       return res;
//     }
//   },
//   error => {
//     console.log('errrrr' + error); // for debug
//     return Promise.reject(error);
//   }
// );

export default service;
