<template>
  <div>
    <div class="m-header-top">
      <div class="top-container">
        <div class="company">{{ $t('other.title') }}</div>
        <div class="languages" @click="toggleDropdown">
          <div class="dropdown" >
            <img class="img" :src="selectedOptiion.icon" alt="">
            <span class="langue ">{{ selectedOptiion.name }}</span>
            <i  :class="{'el-icon-arrow-up': isDropdownOpen,'el-icon-arrow-down': !isDropdownOpen}"></i>
          </div>
          <ul class="options" v-show="isDropdownOpen">
              <li v-for="language in languages" :key="language.value"  @click="selectLanguage(language.value)"> 
                <span>
                  <img class="flag" :src="language.icon" alt="">
                </span>
                <span class="country-name">{{ language.name }}</span>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getLanguageRoutePath, getRootPath} from "@/utils";
import { languages } from "@/config/language";

export default {
  data() {
    return {
      selectedLanguage: getRootPath() || languages[0].value,
      languages: languages,
      isDropdownOpen: false,
      selectedOptiion: {
        icon: sessionStorage.getItem('selectedOptionIcon') || languages[0].icon,
        name: sessionStorage.getItem('selectedOptionName') || languages[0].name
      },
    };
  },
  methods: {
    toggleDropdown(){
      this.isDropdownOpen = !this.isDropdownOpen
    },
    selectLanguage(languageValue) {
      // console.log(43,languageValue);
      const rootPath = getRootPath();
      let routePath, languagePath;

      const currLanguage = this.languages.find(language => language.value === rootPath);

      if (rootPath === languageValue || (!currLanguage && languageValue === this.languages[0].value)) return;

      if (currLanguage) {
        routePath = this.$route.fullPath.split('/').slice(2).join('/');
      } else {
        routePath = this.$route.fullPath;
      }

      if (languageValue === this.languages[0].value) {
        languagePath = '';
      } else {
        languagePath = languageValue;
      }

      const selectedLanguage = this.languages.find(language=>language.value === languageValue)
      if(selectedLanguage) {
        this.selectedOptiion.icon = selectedLanguage.icon
        this.selectedOptiion.name = selectedLanguage.name
        sessionStorage.setItem('selectedOptionIcon', selectedLanguage.icon);
        sessionStorage.setItem('selectedOptionName', selectedLanguage.name);
      }
      

      window.location.href = window.location.origin + (this.$router.options.base || '/') + languagePath + routePath;
    },
  }
}
</script>
<style lang="scss">
$topHeight: 4rem;
.m-header-top {
  height: $topHeight;
  background-color: #000;
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 115rem;
    .company {
      // float: left;
      color: #fff;
      line-height: $topHeight;
    }
    .languages {
      // float: right;
      display: inline-block;
      margin-left: 5rem;
      line-height: $topHeight;
      .el-select {
        .custom-option {
        }
          .el-input--suffix .el-input__inner {
              padding-right: 0px;
              padding-left: 5px;
              border: 2px solid #ebc83e;
              background-color: transparent;
              color: #ebc83e;
              height: 3rem;
          }
          .el-input__inner::placeholder {
            color: #ebc83e; 
            // color: #fff; 
          }
          .el-input {
            .el-select__caret {
              color: #ebc83e;
              // color: #fff;
          }
          .el-input__suffix {
            right: 1px;
          }
          
          
        }
         
      }
      .dropdown {
        width: 12rem;
        position: relative;
        padding: 0.2rem 1.5rem;
        border: 1px solid #ebc83e;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #ebc83e;
        
        .img {
          display: inline-block;
          width: 2rem;
          
        }
        .langue {
          display: inline-block;
          line-height: 2rem;
          // padding: 0 0.8rem;
        }
        
      }
      .options {
          width: 12rem;
          // padding: 0 1rem;
          background-color: #f1f1f1;
          border-radius: 0.5rem;
          color: #000;
          position: absolute;
          top: 4.5rem;
          z-index: 8;
          li {
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            // text-align: center;
            .flag {
              float: left;
              width: 2rem;
              margin: 0 1.5rem;
            }
            .country-name {
              float: right;
            }

          }
          li:hover {
            background-color: #d4e5f7;
          }
      }
        
    }
  }
}

  
</style>