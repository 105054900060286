import {getScrollBox, isType} from "@/utils";

export default {
  data() {
    return {
      scrollBox: null,
      scrollBoxEl: null
    }
  },
  mounted() {
    this.scrollBox = getScrollBox();
    this.scrollBoxEl = this.scrollBox === window ? window.document.documentElement : this.scrollBox;

    this.scrollBox.addEventListener('scroll', this._scrollListener);

    this.$on('hook:beforeDestroy', () => {
      this.scrollBox.removeEventListener('scroll', this._scrollListener);
    });
  },
  methods: {
    _scrollListener() {
      if (this.scrollBoxEl) {
        this.scrollListener(getScrollTop());
      }

      function getScrollTop() {
        return document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      }
    },
    toScrollTop(scrollTop) {
      const self = this;
      // 去掉header的高度
      // const treatedScrollTop = Math.max(scrollTop - (self.$isMobile ? 60 : 150));
      const treatedScrollTop = scrollTop;
      setScrollTop(treatedScrollTop);

      function setScrollTop(scrollTop) {
        document.documentElement.scrollTop = scrollTop;
        document.body.scrollTop = scrollTop;
        window.pageYOffset = scrollTop;
      }
    }
  }
}
