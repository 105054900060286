<template>
  <div ref="container" class="m-home-information">
    <div class="information-title">
      <!-- <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/14.png" alt=""> -->
      <p>{{ $t('title.information') }}</p>
    </div>
    <div class="information-ctnr">
      <div class="new" v-for="item in newsList" :key="item.id">
        <div class="new-img" @click="toInformation(item.id)">
          <img :src="item.cover" alt="" />
        </div>
        <div class="news">
          <div class="new-title">
            <p class="title" @click="toInformation(item.id)">
              {{ getLocalizedTitle(item) }}
            </p>
            <span class="date">{{ item.createTime }}</span>
          </div>
          <p
            v-html="getLocalizedDescription(item)"
            class="new-content"
            @click="toInformation(item.id)"
          ></p>
        </div>
      </div>
    </div>
    <div class="more" @click="toMore">{{ $t('other.more') }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getNews } from '@/api/news';
import { getLanguageRoutePath } from '@/utils';
import animationMixin from '@/mixins/animations';

export default {
  mixins: [animationMixin],
  data() {
    return {
      newsList: [],
      clickCount: 0,
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    toMore() {
      window.location.href =
        window.location.origin + getLanguageRoutePath('MoreInformation');
    },
    toInformation(id) {
      let clickCount = localStorage.getItem('clickCount');
      clickCount = clickCount ? parseInt(clickCount) + 1 : 1;
      localStorage.setItem('clickCount', clickCount);
      this.$router.push({
        path: '/InformationCenter',
        query: { id: id, clickCount: clickCount },
      });
    },
    async getNewsList() {
      const res = await getNews();
      // console.log(51,res.data);
      res.data.data.forEach((item) => {
        item.createTime = dayjs(item.createTime).format('YYYY-MM-DD');
      });
      this.newsList = res.data.data.slice(-4);
    },
    getLocalizedTitle(item) {
      if (this.$isEnglish) {
        return item.title;
      } else if (this.$isChinese) {
        return item.ctitle;
      } else if (this.$isJapanese) {
        return item.jtitle;
      } else if (this.$isKorean) {
        return item.ktitle;
      } else if (this.$isIndia) {
        return item.ititle;
      }
    },

    getLocalizedDescription(item) {
      if (this.$i18n.locale === 'en-US') {
        return item.description;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return item.cdescription;
      } else if (this.$i18n.locale === 'ja') {
        return item.jdescription;
      } else if (this.$i18n.locale === 'ko') {
        return item.kdescription;
      } else if (this.$i18n.locale === 'hi') {
        return item.idescription;
      }
    },
    getLocalizedContent(item) {
      if (this.$isEnglish) {
        return item.content;
      } else if (this.$isChinese) {
        return item.ccontent;
      } else if (this.$isJapanese) {
        return item.jcontent;
      } else if (this.$isKorean) {
        return item.kcontent;
      } else if (this.$isIndia) {
        return item.icontent;
      }
    },
  },
};
</script>

<style lang="scss">
.m-home-information {
  margin: 0 auto;
  padding: 6rem 0;
  // height: 70rem;
  background-color: #111111;
  background-image: url('https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/15.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .information-title {
    font-size: 4rem;
    color: #ededed;
    line-height: 10rem;
  }
  .information-ctnr {
    margin: 5rem auto 3rem;
    max-width: 120rem;
    display: flex;
    align-items: center;
    .new {
      float: left;
      margin: 1rem;
      width: 30rem;
      .new-img {
        width: 28rem;
        height: 20rem;
        margin-bottom: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .news {
        padding: 0rem 0rem 2rem 0.5rem;
        float: left;
        width: 28rem;
        .new-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            max-width: 18rem;
            color: #fff;
            font-size: 2rem;
            text-align: left;
            text-decoration: none;
            display: -webkit-box;
            overflow: hidden;
            word-break: break-all;
            white-space: normal;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
          }
          .date {
            color: #fff;
            font-size: 1.5rem;
          }
        }

        .new-content {
          line-height: 2.8rem;
          color: rgb(207, 206, 206);
          font-size: 1.4rem;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .more {
    margin: 0 auto;
    max-width: 10rem;
    padding: 1rem;
    color: #ebc83e;
    border: 1px solid #ebc83e;
    border-radius: 0.5rem;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .m-home-information {
    // height: 80rem;
    padding: 3rem 0;
    .information-title {
      font-size: 2.2rem;
      color: #ededed;
      line-height: 5rem;
    }

    .information-ctnr {
      margin: 2rem auto;
      max-width: 35rem;
      display: flex;
      flex-wrap: wrap;

      .new {
        // float: left;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-right: 0rem;
        width: 33rem;
        .new-img {
          width: 38%;
          height: 7rem;
          // background-color: #fff;
          // height: 10rem;
          margin-bottom: 0;
        }
        .news {
          padding: 1rem 0.5rem 1rem 1rem;
          width: 35rem;
          // background-color: tan;
          .new-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              max-width: 15rem;
              color: #fff;
              font-size: 1.5rem;
              text-align: left;
              text-decoration: none;
              display: -webkit-box;
              overflow: hidden;
              word-break: break-all;
              white-space: normal;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              text-overflow: ellipsis;
            }
            .date {
              color: #fff;
              font-size: 1.2rem;
            }
          }
          .new-content {
            color: rgb(207, 206, 206);
            font-size: 1.3rem;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .new-center {
        .new {
          padding: 1.5rem 0.5rem;
          margin: auto;
          width: 35rem;
          // background-color: tan;
          .new-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              max-width: 15rem;
              color: #fff;
              font-size: 1.6rem;
              text-align: left;
              text-decoration: none;
              display: -webkit-box;
              overflow: hidden;
              word-break: break-all;
              white-space: normal;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              text-overflow: ellipsis;
            }
            .date {
              color: #fff;
              font-size: 1.4rem;
            }
          }

          .new-content {
            color: #fff;
            font-size: 1.4rem;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .new:nth-last-child(n + 4) {
          display: none;
        }
      }
    }
    .more {
      margin: 0 auto;
      padding: 0.6rem;
      color: #ebc83e;
      border: 1px solid #ebc83e;
      border-radius: 0.5rem;
      font-size: 1.3rem;
    }
  }
}
</style>
