export const languages = [
  {
    name: 'English',
    value: 'en-US',
    icon:'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag1.png'
  },
  {
    name: '中文',
    value: 'zh-Hans-CN',
    icon:'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag2.png'
  },
  {
    name: '日语',
    value: 'ja',
    icon:'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag3.png'
  },
  {
    name: '한국어',
    value: 'ko',
    icon:'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag5.png'
  },
  {
    name: 'हिन्दी',
    value: 'hi',
    icon:'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/flag4.png'
  }
]

// export const languages = [
//   {
//     name: 'languages.en',
//     value: 'en-US'
//   },
//   {
//     name: 'languages.zh',
//     value: 'zh-Hans-CN'
//   },
//   {
//     name: 'languages.ja',
//     value: 'ja'
//   },
//   {
//     name: 'languages.ko',
//     value: 'ko'
//   },
//   {
//     name: 'languages.hi',
//     value: 'hi'
//   }
// ]