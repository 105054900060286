<template>
  <div ref="container" class="m-home-cooperation">
    <div class="cooperation-title">
      <p>{{ $t('title.cooperation') }}</p>
    </div>
    <div class="cooperation-ctnr ">
      <div class="flow" v-for="(cooperate,index) in cooperation" :key="index" :cooperate="cooperate">
        <img class="icon" :src="cooperate.icon" alt="">
        <p>{{ cooperate.title }}</p>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import animationMixin from '@/mixins/animations';
import { cooperation } from './cooperation';
export default {
  mixins: [animationMixin],
  data() {
    return {
      cooperation: cooperation.map((cooperate) => ({
        icon: cooperate.icon,
        title: this.$t(cooperate.title)
      }))
    };
  }
};
</script>

<style lang="scss">
.m-home-cooperation {
  margin: 0 auto;
  padding: 8rem 0 4rem;
  background-color: #111111;
  background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .cooperation-title {
    // margin-bottom: 6rem;
    font-size: 4rem;
    color: #ededed;
  }
  .cooperation-ctnr {
    margin: 2rem auto;
    max-width: 140rem;
    display: flex;
    
    .flow {
      margin: 5rem auto;
      padding: 0 2rem;
      
      .icon {
        width: 14rem;
      }
      p {
        margin-top: 3rem;
        color: #fff;
        font-size: 2.5rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .m-home-cooperation {
    height: 60rem;
    padding: 4rem 0 6rem;
    .cooperation-title {
      font-size: 2.2rem;
      color: #ededed;
    }
    .cooperation-ctnr {
      display: flex;
      flex-wrap: wrap;
      .flow {
        width: 50%;
        margin: 1rem auto;
        .icon {
        width: 9rem;
      }
        p {
          margin-top: 0.5rem;
          color: #fff;
          font-size: 1.4rem;
        }
      }
    }
    
  }
}
</style>
