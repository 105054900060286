<template>
  <div class="p-successful">
    <div class="successful-title">
      <span class="successful">{{ $t('successfulCase.caseDetails') }}</span>
      <span class="location"
        >{{ $t('successfulCase.location') }}：{{
          $t('successfulCase.homepage')
        }}
        > {{ $t('successfulCase.caseDetails') }}</span
      >
    </div>
    <div class="successful-content">
      <div class="successful-content-ctnr">
        <div class="content-title">{{ getLocalizedTitle(caseDetails) }}</div>
        <div class="content-message">
          <span
            >{{ $t('successfulCase.time') }}：{{ caseDetails.createTime }}</span
          >
          <span class="divider"></span>
          <!-- 阅读量 -->
          <span>{{ $t('other.pv') }}: {{ clickCount + fpv }}</span>
        </div>
        <div class="content">
          <p>{{ getLocalizedDescription(caseDetails) }}</p>
          <!-- <img class="image" :src="caseDetails.cover" alt=""> -->
          <!-- <p>{{ getLocalizedContent(caseDetails) }}</p> -->
          <p
            class="content-content"
            v-html="getLocalizedContent(caseDetails)"
          ></p>
        </div>
      </div>
    </div>
    <About></About>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getCase } from '@/api/case';
import About from '../home/About';
import content from './content';

export default {
  components: { About },
  data() {
    return {
      content: content.detail,
      caseDetails: {},
      fpv: 333, //虚拟浏览量
    };
  },
  created() {
    const id = this.$route.query.id;
    let clickCount = localStorage.getItem('clickCount');
    clickCount = clickCount ? parseInt(clickCount) : 0;
    // console.log(45, id);
    // console.log(46, clickCount);
    this.clickCount = clickCount;
    // this.fpv = Math.floor(Math.random() * 500);
    this.getCaseDetails({ id });
  },
  methods: {
    async getCaseDetails(id) {
      const res = await getCase(id);
      // console.log(46, res.data);
      this.caseDetails = res.data.data;
      this.caseDetails.createTime = dayjs(this.caseDetails.createTime).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    },
    getLocalizedTitle(caseDetails) {
      if (this.$i18n.locale === 'en-US') {
        return caseDetails.title;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return caseDetails.ctitle;
      } else if (this.$i18n.locale === 'ja') {
        return caseDetails.jtitle;
      } else if (this.$i18n.locale === 'ko') {
        return caseDetails.ktitle;
      } else if (this.$i18n.locale === 'hi') {
        return caseDetails.ititle;
      }
    },
    getLocalizedDescription(caseDetails) {
      if (this.$i18n.locale === 'en-US') {
        return caseDetails.description;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return caseDetails.cdescription;
      } else if (this.$i18n.locale === 'ja') {
        return caseDetails.jdescription;
      } else if (this.$i18n.locale === 'ko') {
        return caseDetails.kdescription;
      } else if (this.$i18n.locale === 'hi') {
        return caseDetails.idescription;
      }
    },
    getLocalizedContent(caseDetails) {
      if (this.$i18n.locale === 'en-US') {
        return caseDetails.content;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return caseDetails.ccontent;
      } else if (this.$i18n.locale === 'ja') {
        return caseDetails.jcontent;
      } else if (this.$i18n.locale === 'ko') {
        return caseDetails.kcontent;
      } else if (this.$i18n.locale === 'hi') {
        return caseDetails.icontent;
      }
    },
  },
};
</script>
<style lang="scss">
.p-successful {
  margin-top: 20.5rem;
  background-color: #1b1b1b;
  .successful-title {
    max-width: 115rem;
    margin: 0 auto;
    height: 6rem;
    background-color: #1b1b1b;
    .successful {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 6rem;
      float: left;
    }
    .location {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 6rem;
      float: right;
    }
  }
  .successful-content {
    margin: 0 auto;
    padding: 6rem 0 5rem;
    // height: 70rem;
    background-color: #111111;
    background-image: url('https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/15.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .successful-content-ctnr {
      max-width: 110rem;
      margin: auto;
      .content-title {
        color: #fff;
        font-size: 2.5rem;
        text-align: center;
      }
      .content-message {
        max-width: 60rem;
        margin: auto;
        // display: flex;
        // justify-content: space-around;
        color: #fff;
        font-size: 1.4rem;
        padding: 2rem 0;
        text-align: center;
        .divider {
          margin: 0 2rem;
          border: 1px solid #fff;
        }
        .author {
          margin-right: 5rem;
          display: inline-block;
        }
      }
      .content {
        overflow: auto;
        .image {
          // width: 70%;
          display: block;
          margin: 1.5rem 0;
          margin-left: auto;
          margin-right: auto;
        }
        p {
          color: #ccc;
          line-height: 3.5rem;
          text-indent: 2em;
          font-size: 1.6rem;
          text-align: justify;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .p-successful {
    margin-top: 0;
    background-color: #1b1b1b;
    .successful-title {
      max-width: 35rem;
      margin: 0 auto;
      height: 5rem;
      background-color: #1b1b1b;
      .successful {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 6rem;
        float: left;
      }
      .location {
        color: #fff;
        font-size: 1.3rem;
        font-weight: 400;
        float: right;
      }
    }
    .successful-content {
      margin: 0 auto;
      padding: 3rem 0 5rem;
      .successful-content-ctnr {
        max-width: 35rem;
        margin: auto;
        .content-title {
          color: #fff;
          font-size: 1.6rem;
          text-align: center;
        }
        .content-message {
          max-width: 35rem;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          color: #fff;
          font-size: 1rem;
          padding: 1.6rem 0;
          text-align: center;
          .author {
            display: inline-block;
          }
        }
        .content {
          overflow: auto;
          .image {
            width: 100%;
          }
          p {
            color: #ccc;
            line-height: 3.5rem;
            text-indent: 2em;
            font-size: 1.4rem;
            text-align: justify;
          }
          .content-content {
            img {
              max-width: auto;
              height: auto;
            }
          }
        }
      }
    }
  }
}
</style>
