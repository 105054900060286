/*
    dayjs日期处理工具
*/
import Vue from 'vue'
import dayjs from 'dayjs'

// dayjs默认语言是英文,配置为中文
import 'dayjs/locale/zh-cn' // 加载中文语言包
dayjs.locale('zh-cn')// 全局使用

// dayjs获取当前时间
dayjs().format('YYYY-MM-DD HH:mm:ss')

// 配置相对时间插件
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

/*
    全局模板过滤器
    参数1：过滤器名称
    参数2：过滤器函数
    使用方式：{{表达式||过滤器名称}}
*/
Vue.filter('relativeTime', value => {
  return dayjs().to(dayjs(value))
})
