<template>
  <div class="m-mobile-header">
    <div class="mobile-header-container">
      <div class="logo" @click="toRoute({routeValue: '', sectionValue: ''})">
        <img class="logo1" src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo.png" alt="logo">
        <img class="logo2" :src="getLogoUrl()" alt="logo" :class="{ 'chinese-locale': isChineseLocale }">
      </div>
      <div class="actions">
        <a class="phone" href="tel:639171806668">
          <img  src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/phone1.svg" alt="phone">
        </a>
        <a class="address" href="#" target="" @click="showMap">
          <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/address1.svg" alt="address">
        </a>
        <div class="languages"  @click="toggleDropdown">
          <div class="dropdown" >
            <img class="img" :src="selectedOptiion.icon" alt="">
            <span class="langue ">{{ selectedOptiion.name }}</span>
            <i  :class="{'el-icon-arrow-up': isDropdownOpen,'el-icon-arrow-down': !isDropdownOpen}"></i>
          </div>
          <ul class="options" v-show="isDropdownOpen">
              <li v-for="language in languages" :key="language.value"  @click="selectLanguage(language.value)"> 
                <span>
                  <img class="flag" :src="language.icon" alt="">
                </span>
                <span class="country-name">{{ language.name }}</span>
              </li>
          </ul>
          <!-- <div class="dropdown">
            <el-select v-model="selectedLanguage" @change="selectLanguage(selectedLanguage)" placeholder="请选择" style="width: 7.5rem;">
              <el-option
                v-for="language in languages"
                :key="language.value"
                :label="language.name"
                :value="language.value"
                :style="{ 'background-color': '#f7f7f7','margin': '0 -0.5rem' }">
                <span style="float: left">
                  <img style="width: 2rem; height: 1.25rem; margin-top: 1rem;" :src="language.icon" alt="">
                </span>
                <span style="margin-left: 1rem;">{{ language.name }}</span>
              </el-option>
              
            </el-select>
          </div> -->
        </div>
        <div class="current-section" @click.stop="switchMobileSideBar">
          <div v-show="!sideBarState" class="current-section-content iconfont icon-menu"></div>
          <div v-show="sideBarState" class="current-section-content iconfont icon-cross"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getLanguageRoutePath, getRootPath} from "@/utils";
import {languages} from "@/config/language";
import menuActions from "@/mixins/menuActions";

export default {
  mixins: [menuActions],
  data() {
    return {
      selectedLanguage: getRootPath() || languages[0].value,
      currentSection: '',
      languages: languages,
      isDropdownOpen: false,
      selectedOptiion: {
        icon: sessionStorage.getItem('selectedOptionIcon') || languages[0].icon,
        name: sessionStorage.getItem('selectedOptionName') || languages[0].name
      },
    }
  },
  computed: {
    isChineseLocale(){
      return this.$i18n.locale === 'zh-Hans-CN';
    },
    sideBarState() {
      return this.$store.state.mobileSideBarState;
    },
    menu() {
      return this.$store.state.pageMenu;
    },
    currentSectionName() {
      return this.$store.state.currentSectionName;
    }
  },
  methods: {
    toggleDropdown(){
      this.isDropdownOpen = !this.isDropdownOpen
    },
    getLogoUrl() {
      const logoUrls = {
        'en-US': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/e.png',
        'zh-Hans-CN': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/zh.png',
        'ja': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/ja.png',
        'ko': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/ko.png',
        'hi': 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/logo/hi.png'
      };
      return logoUrls[this.$i18n.locale] || '';
    },
    showMap(){
      // this.$router.push({ path: '/Map' });
      window.location.href = window.location.origin + getLanguageRoutePath('Map');
    },

    selectLanguage(languageValue) {
      const rootPath = getRootPath();
      let routePath, languagePath;

      const currLanguage = this.languages.find(language => language.value === rootPath);

      if (rootPath === languageValue || (!currLanguage && languageValue === this.languages[0].value)) return;

      if (currLanguage) {
        routePath = this.$route.fullPath.split('/').slice(2).join('/');
      } else {
        routePath = this.$route.fullPath;
      }

      if (languageValue === this.languages[0].value) {
        languagePath = '';
      } else {
        languagePath = languageValue;
      }

      const selectedLanguage = this.languages.find(language=>language.value === languageValue)
      if(selectedLanguage) {
        this.selectedOptiion.icon = selectedLanguage.icon
        this.selectedOptiion.name = selectedLanguage.name
        sessionStorage.setItem('selectedOptionIcon', selectedLanguage.icon);
        sessionStorage.setItem('selectedOptionName', selectedLanguage.name);
      }

      window.location.href = window.location.origin + (this.$router.options.base || '/') + languagePath + routePath;
    },
    setCurrentSection(itemKey) {
      const item = this.menu.find(item => item.key === itemKey);

      if (item) {
        this.currentSection = this.$t(item.name);
      }
    },
    switchMobileSideBar() {
      this.$store.mutations.switchMobileSideBarState();
    }
  }
}
</script>

<style lang="scss">
$height: 5rem;
$lineHeight: 6.5rem;

.m-mobile-header {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: $height;
  width: 100%;
  background-color: #1b1b1b;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  z-index: 1000;
  .mobile-header-container {
    position: relative;
    .logo {
      position: absolute;
      left: 1.2rem;
      vertical-align: top;
      height: $height;
      line-height: $height;
      .logo1 {
        width: 3.6rem;
        vertical-align: middle;
        filter: drop-shadow(-2px 2px 4px black);
      }
      .logo2 {
        height: 4.5rem;
        padding: 1rem 0.8rem;
        vertical-align: middle;
        filter: drop-shadow(-2px 2px 4px black);
      }
      .chinese-locale {
        padding: 0rem;
      }
    }
    .title {
      margin-left: 7rem;
      line-height: $lineHeight;
      font-size: 1.4rem;
      font-weight: bold;
      color: #ebc83e;
    }
    .actions {
      position: absolute;
      right: 0;
      top: 0;
      .phone,
      .address,
      .languages,
      .qr-code,
      .current-section {
        display: inline-block;
        vertical-align: top;
        padding: 0 0.5rem;
        line-height: $height;
      }
      .phone{
        width: 3rem;
        padding-top: 0.5rem ;
        img {
          width: 100%;
        }
      }
      .address {
        width: 3rem;
        padding-top: 0.5rem;
        img {
          width: 2rem;
        }
      }
      .qr-code {
        color: #ebc83e;
      }
      .languages {
        padding-top: 1.2rem;
        vertical-align: top;
        // .el-select {
        //     .el-input--suffix .el-input__inner {
        //         padding-right: 0px;
        //         padding-left: 5px;
        //         border: 2px solid #ebc83e;
        //         background-color: transparent;
        //         color: #ebc83e;
        //         height: 3rem;
        //     }
        //     .el-input__inner::placeholder {
        //       color: #ebc83e; 
        //     }
        //     .el-input {
        //       .el-select__caret {
        //         color: #ebc83e;
        //     }
        //     .el-input__suffix {
        //       right: -2px;
        //       top: 2px;
        //     }
        //   }
        // }

        .dropdown {
          width: 10rem;
          position: relative;
          padding: 0.2rem 1rem;
          border: 1px solid #ebc83e;
          border-radius: 0.4rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: #ebc83e;
          
          .img {
            display: inline-block;
            width: 2rem;
            
          }
          .langue {
            margin-left: 0.2rem;
            display: inline-block;
            line-height: 2rem;
            // padding: 0 0.8rem;
          }
          
        }
        .options {
            width: 12rem;
            // padding: 0 1rem;
            background-color: #f1f1f1;
            border-radius: 0.5rem;
            color: #000;
            position: absolute;
            top: 4.5rem;
            z-index: 8;
            li {
              border-bottom: 1px solid #f8f8f8;
              border-radius: 0.4rem;
              display: flex;
              align-items: center;
              // text-align: center;
              .flag {
                float: left;
                width: 2.5rem;
                margin: 0 1.5rem;
              }
              .country-name {
                float: right;
                line-height: 4.5rem;
              }

            }
            li:hover {
              background-color: #d4e5f7;
            }
        }

      }
      .current-section {
        padding: 0 1rem;
        .current-section-content {
          font-weight: bold;
          color: #ebc83e;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .m-mobile-header {
    display: block;
  }
}
</style>
