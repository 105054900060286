export const cooperation = [
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2/1.png',
    title: 'cooperation.detail'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2/3.png',
    title: 'cooperation.contract'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2/4.png',
    title: 'cooperation.payment'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2/2.png',
    title: 'cooperation.talents'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2/5.png',
    title: 'cooperation.serve'
  },
  {
    icon: 'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/2/6.png',
    title: 'cooperation.afterSale'
  },

]
