import { getLanguageRoutePath, obj2qs } from "@/utils";

export default {
  data() {

  },
  methods: {
    toRoute(route) {
      const languageTargetRoutePath = getLanguageRoutePath(route.routeValue);
      const entireLanguageTargetRoutePath = getLanguageRoutePath(route.routeValue + (route.sectionValue ? obj2qs({
        section: route.sectionValue
      }) : ''));

      if (this.isOnRoute(languageTargetRoutePath)) {
        if (this.$store) {
          this.$store.actions.goToSection(route.sectionValue);
        }
      } else {
        window.location.href = entireLanguageTargetRoutePath;
      }
    },
    isOnRoute(path) {
      return path === window.location.pathname;
    }
  }
}
