import Vue from 'vue'
import VueI18n from 'vue-i18n';
import router from '@/router';
import Components from '@/components';
import { getRootPath } from '@/utils';
import { languages } from '@/config/language';
import en from '@/languages/en-US.json';
import zh from '@/languages/zh-Hans-CN.json';
import ja from '@/languages/ja.json';
import ko from '@/languages/ko.json';
import hi from '@/languages/hi.json';
import store from '@/store';
import requestAnimationFrame from '@/lib/requestAnimationFrame';
import { setPageInfo } from "@/pageInfo";
// import '@/styles/inlinesource.css';
import '@/styles/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/dayjs'
import * as VueGoogleMaps from 'vue2-google-maps';


import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
  preLoad: 1.3, // 预加载高度的比例
  error: '../src/assets/error.svg', // 图片加载失败时显示的占位图片
  loading: '../src/assets/loading.svg', // 图片加载中显示的占位图片
  attempt: 1 // 尝试加载次数
});

 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBvLca54bIaJoZ8GbqTWvFxFWiRmsuvaeE',
    libraries: 'places', 
  },
});
Components.init(Vue);
Vue.use(ElementUI);
Vue.use(VueI18n);

Vue.config.productionTip = false;
// Vue.prototype.$eventBus = new Vue();
const clientWidth = window.document.documentElement.clientWidth;

if (clientWidth < 768) {
  Vue.prototype.$device = 'mobile';
  Vue.prototype.$isMobile = true;
} else if (clientWidth >= 768 && clientWidth <= 1024) {
  Vue.prototype.$device = 'pad';
  Vue.prototype.$isMobile = false;
} else {
  Vue.prototype.$device = 'pc';
  Vue.prototype.$isMobile = false;
}

const rootPath = getRootPath();

const language = languages.find(language => language.value === rootPath);
const i18n = new VueI18n({
  locale: language ? language.value : languages[0].value,
  fallbackLocale: languages[0].value,
  messages: {
    'en-US': en,
    'zh-Hans-CN': zh,
    'ja': ja,
    'ko': ko,
    'hi': hi,
  }
});

Vue.prototype.$isEnglish = (language || languages[0]).value === 'en-US';
Vue.prototype.$isChinese = (language || languages[1]).value === 'zh-Hans-CN';
Vue.prototype.$isJapanese = (language || languages[2]).value === 'ja';
Vue.prototype.$isKorean = (language || languages[3]).value === 'ko';
Vue.prototype.$isIndia = (language || languages[4]).value === 'hi';

Vue.prototype.$store = store;

Vue.prototype.$isSupportWebp = (function() {
  try {
    return (
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp") == 0
    );
  } catch (err) {
    return false;
  }
}());

setPageInfo();

new Vue({
  router,
  i18n,
  render: h => h('router-view'),
  mounted() {
    const globeClickListener = () => {
      store.mutations.setMobileSideBarState(false);
    };
    window.document.body.addEventListener('click', globeClickListener);

    this.$on('hook:beforeDestroy', () => {
      window.document.body.removeEventListener('click', globeClickListener);
    });

    document.dispatchEvent(new Event('prerender-event'));
  }
}).$mount('#app');
