export const buttons = [
  {
    name: 'other.whatsapp',
    href: 'https://api.whatsapp.com/send?phone=639457731999',
    // href:'https://wa.me/message/IEXWNZ2PNKLID1',
    icon:
      'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/whatsapp1.svg',
  },
  {
    name: 'other.wechat',
    href: 'https://work.weixin.qq.com/kfid/kfc31d13b69badd3fc7',
    icon:
      'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/weixin1.svg',
  },
  {
    name: 'other.facebook',
    href: 'https://www.facebook.com/365TheOne',
    icon:
      'https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/facebook1.svg',
  },
];
