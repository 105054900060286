import { cloneDeep } from '@/utils';

export const homeMenu = [
  {
    key: 'home',
    name: 'menu.home',
    routeValue: '',
    sectionValue: 'home',
  },
  {
    key: 'services',
    name: 'menu.services',
    routeValue: '',
    sectionValue: 'services',
  },
  {
    key: 'successfulCase',
    name: 'menu.successfulCase',
    routeValue: '',
    sectionValue: 'successfulCase',
  },
  {
    key: 'cooperation',
    name: 'menu.cooperation',
    routeValue: '',
    sectionValue: 'cooperation',
  },
  {
    key: 'information',
    name: 'menu.information',
    routeValue: '',
    sectionValue: 'information',
  },
  {
    key: 'about',
    name: 'menu.about',
    routeValue: '',
    sectionValue: 'about',
  },
];

const homeMenuProxy = homeMenu.reduce(proxyFn, {});

export default {
  homeMenu: homeMenuProxy,
};

function proxyFn(proxy, item) {
  proxy[item.key] = item.value;

  return proxy;
}
