<template>
  <div class="p-more">
    <div class="more-title">
      <!-- <span class="more">更多新闻</span> -->
      <span class="location">{{ $t('menu.home') }}  <span style="margin: 0 2px">/</span>  {{ $t('menu.information') }}  <span style="margin: 0 2px">/</span>   {{ $t('other.more') }}</span>
    </div>
    <div class="more">
      <div class="more-content">
        <div class="more-news"  v-for="item in displayData" :key="item.id" >
          <div class="news-left" @click="toInformation(item.id)">
            <img :src="item.cover" alt="">
          </div>
          <div class="news-right">
            <div class="news-title" @click="toInformation(item.id)">
              {{ getLocalizedTitle(item) }}
            </div>
            <div @click="toInformation(item.id)">
              <p class="news-content" v-html="getLocalizedDescription(item)"></p>
              <!-- {{ getLocalizedContent(item) }} -->
            </div>
            <div class="news-date">
              <span class="date">{{ item.createTime}}</span>
              <!-- <span class="read-count">阅读量：{{ clickCount + item.fpv }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="[5,8, 10, 15, 20, 25, 30]"
        :page-size="pageSize"
        :total="total"
        layout="total, prev, pager, next"
        @size-change="onSizeChange"
        @current-change="onCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import dayjs from 'dayjs'
import {getNews} from '@/api/news'
import { getLanguageRoutePath } from "@/utils";
import animationMixin from '@/mixins/animations';

export default {
  mixins: [animationMixin],
  data() {
    return {
      total: 0,
      newsList:[],
      clickCount: 0,
      currentPage: 1,
      pageSize: 5
    };
  },
  created(){
    this.getNewsList()
  },
  computed: {
    displayData() {
      const startIndex = (this.currentPage - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
 
      return this.newsList.slice(startIndex, endIndex)
    }
  },
  methods: {

    toInformation(id) {
      let clickCount = localStorage.getItem('clickCount'); 
      clickCount = clickCount ? parseInt(clickCount) + 1 : 1; 
      localStorage.setItem('clickCount', clickCount); 
      this.$router.push({ path: '/InformationCenter', query: { id: id, clickCount: clickCount } });
    },
    async getNewsList() {
      const res = await getNews()
      // console.log(51,res.data);
      res.data.data.forEach(item => {
        item.createTime = dayjs(item.createTime).format('YYYY-MM-DD')

      })
      this.newsList = res.data.data
      this.total = this.newsList.length
      
    },
    getLocalizedTitle(item) {
      if (this.$isEnglish) {
        return item.title;
      } else if (this.$isChinese) {
        return item.ctitle;
      } else if (this.$isJapanese) {
        return item.jtitle;
      }else if (this.$isKorean) {
        return item.ktitle;
      }else if (this.$isIndia) {
        return item.ititle;
      }
    },
    getLocalizedDescription(item) {
      if (this.$i18n.locale === 'en-US') {
        return item.description;
      } else if (this.$i18n.locale === 'zh-Hans-CN') {
        return item.cdescription;
      } else if (this.$i18n.locale === 'ja') {
        return item.jdescription;
      }else if (this.$i18n.locale === 'ko') {
        return item.kdescription;
      }else if (this.$i18n.locale === 'hi') {
        return item.idescription;
      }
    },
    
    getLocalizedContent(item) {
      if (this.$isEnglish) {
        return item.content;
      } else if (this.$isChinese) {
        return item.ccontent;
      } else if (this.$isJapanese) {
        return item.jcontent;
      }else if (this.$isKorean) {
        return item.kcontent;
      }else if (this.$isIndia) {
        return item.icontent;
      }
    },
    onSizeChange(val) {
      this.pageSize = val
    },
    onCurrentChange(val) {
      this.currentPage = val
    }
  }
};
</script>

<style lang="scss">
.p-more {
  height: 100%;
  margin-top: 20.5rem;
  background-color: #1b1b1b;
  .more-title {
    max-width: 115rem;
    margin: 0 auto;
    height: 6rem;
    background-color: #1b1b1b;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 6rem;
    color: #fff;
  }
  .more {
    
    background-color: #111;
    .more-content {
      padding: 2rem;
      max-width: 120rem;
      margin: auto;
      // background-color: #fff;
    }
    .more-news {
      display: flex;
      justify-content: space-between;
      padding: 4rem 0;
      width: 100%;

      border-bottom: 1px solid #666;
      .news-left {
        width: 32%;
        height: 22rem;
        cursor: pointer;
        img {
          width: 90%;
          height: 100%;
        }
      }
      .news-right {
        padding: 0.5rem;
        width: 68%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .news-title {
          font-size: 2.2rem;
          color: #fff;
          cursor: pointer;
        }
        .news-content {
          cursor: pointer;
          color: #ccc;
          font-size: 1.6rem;
          line-height: 3rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .news-date {
          .date {
            display: inline-block;   
            padding: 0.5rem 1.5rem;
            border: 1px solid #ebc83e;
            color: #ebc83e;
            font-size: 1.5rem;
          }
          .read-count {
            color: #ccc;
            margin-left: 2rem;
          }
        }
      }
      
    }
  }
  .pagination {
    width: 100%;
    background-color: #111111;
    margin: 0;
    padding: 2rem 0 4rem;
    .el-pagination {
      max-width: 115rem;
      margin: 0 auto;
      background-color: #111111;
      
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ebc83e;
    color: #FFF;
  }


  
}

@media screen and (max-width: 767px) {
  .p-more {
    // height: 100%;
    margin-top: 0rem;
    background-color: #111;
    .more-title {
      max-width: 115rem;
      margin-left: 2rem;
      height: 4rem;
      background-color: #1b1b1b;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 4rem;
      color: #fff;
    }
    .more {
      background-color: #111;
      .more-content {
        padding: 2rem;
        max-width: 40rem;
        margin: auto;
        // background-color: #fff;
      }
      .more-news {
        display: flex;
        justify-content: space-between;
        padding: 2rem 0;
        width: 100%;

        border-bottom: 1px solid #666;
        .news-left {
          width: 32%;
          height: 7rem;
          cursor: pointer;
          img {
            width: 90%;
            height: 100%;
          }
        }
        .news-right {
          padding: 0.1rem;
          width: 68%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          
          .news-title {
            font-size: 1.5rem;
            color: #fff;
            cursor: pointer;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .news-content {
            cursor: pointer;
            color: #ccc;
            font-size: 1.3rem;
            line-height: 2rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .news-date {
            .date {
              display: inline-block;   
              padding: 0;
              // border: 1px solid #ebc83e;
              border: none;
              color: #ccc;
              font-size: 1.1rem;
            }
            .read-count {
              font-size: 1.2rem;
              color: #ebc83e;
              margin-left: 2rem;
            }
          }
        }
        
      }
    }
    .pagination {
    width: 100%;
    background-color: #111111;
    margin: 0;
    padding: 0rem 0 1.5rem;
    .el-pagination {
      max-width: 35rem;
      margin: 0 auto;
      // padding: 0 2rem;
      background-color: #111111;
      
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ebc83e;
    color: #FFF;
  }
  }
  
}
</style>

