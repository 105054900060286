<template>
  <div class="m-home-consult">
    <div class="consult-title">
      <!-- <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/12.png" alt=""> -->
      <p>{{ $t('title.why') }}</p>
    </div>
    <div class="consult-ctnr">
      <div class="consult" v-for="(info,index) in consult" :key="index" :info="info">
        <div class="consult-top">
          <img class="icon" :src="info.icon" alt=""/>
          <p class="title">{{ info.title }}</p>
          <div class="line"></div>
        </div>
        <p class="consult-introduce">{{ info.introduce }}</p>
        <!-- <div class="online-consult">在线咨询</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { consult } from './consult';
import animationMixin from '@/mixins/animations';

export default {
  mixins: [animationMixin],
  data() {
    return {
      consult: consult.map((info) => ({
        icon: info.icon,
        title: this.$t(info.title),
        introduce: this.$t(info.introduce)
      }))
    };
  }
};
</script>

<style lang="scss">
.m-home-consult {
  margin: 0 auto;
  padding: 8rem 0 10rem;
  // height: 70rem;
  background-color: #111111;
  background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/7.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .consult-title {
    font-size: 4rem;
    color: #ededed;
  }
  .consult-ctnr {
    max-width: 120rem;
    margin: 8rem auto;
    display: flex;
    justify-content: space-between;
    .consult {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 2rem;
      width: 24rem;
      height: 32rem;
      background-color: #fff;
      .consult-top {
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .title {
          font-size: 1.8rem; 
          font-weight: 600;
        }
        .line {
          margin-top: 0.3rem;
          width: 3rem;
          height: 0.1rem;
          border: 0.1rem solid #a12327;
        }
      }
      
      .consult-introduce {
        height: 10rem;
        color: rgb(29, 28, 28);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .online-consult {
        width: 10rem;
        padding: 0.5rem 1rem;
        border: 0.1rem solid #000;
        cursor: pointer;
      }
    }
    .consult:hover {
      background-color: #a12327;
      background-color: #a12327;
      // .icon:nth-child(1) {
      //   content: url(../../../assets/icon/10.png);
      // }
      // .icon:nth-child(2) {
      //   content: url(../../../assets/icon/14.png);
      // }
      // .icon:nth-child(1) {
      //   content: url(../../../assets/icon/12.png);
      // }
      // .icon:nth-child(1) {
      //   content: url(../../../assets/icon/16.png);
      // }
      .title {
       font-weight: 600;
       color: #eee;
      }
      .line {
        border: 0.1rem solid #eee;
      }
      .consult-introduce {
        color: #eee;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .online-consult {
        border: 0.1rem solid #fff;
        cursor: pointer;
        color: #a12327;
        background-color: #eee;
      }
    }
    .consult:nth-child(1):hover .icon {
      content: url(../../../assets/icon/10.png);
    }

    .consult:nth-child(2):hover .icon {
      content: url(../../../assets/icon/13.png);
    }

    .consult:nth-child(3):hover .icon {
      content: url(../../../assets/icon/12.png);
    }

    .consult:nth-child(4):hover .icon {
      content: url(../../../assets/icon/16.png);
    }
      }
      
    }
@media screen and (max-width: 767px) {
  .m-home-consult {
    // height: 70rem;
    padding: 4rem 0;
    .consult-title {
      padding: 0 2rem;
      font-size: 2.2rem;
      color: #ededed;
    }
    .consult-ctnr {
      padding: 1.2rem;
      margin: 3rem auto;
      display: flex;
      flex-wrap: wrap;
      .consult {
        margin-bottom: 1.2rem;
        padding: 1rem 0.5rem;
        width: 17rem;
        height: 25rem;
        background-color: #fff;
        .consult-top {
          height: 40%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .icon {
            width: 4rem;
          }
          .title {
            font-size: 1.4rem; 
            font-weight: 600;
          }
          .line {
            margin-top: 0.3rem;
            width: 3rem;
            height: 0.1rem;
            border: 0.1rem solid #a12327;
          }
        }
        .consult-introduce {
          font-size: 1.3rem;
          height: 9rem;
          color: rgb(29, 28, 28);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp:5;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .online-consult {
          width: 9rem;
          padding: 0.4rem 0.8rem;
          font-size: 1.2rem;
          border: 0.1rem solid #000;
          cursor: pointer;
        }
      }
      .consult:hover{
        background-color: #a12327;
        .title {
        font-weight: 600;
        color: #eee;
        }
        .line {
          border: 0.1rem solid #eee;
        }
        .consult-introduce {
          color: #eee;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .online-consult {
          border: 0.1rem solid #fff;
          cursor: pointer;
          color: #a12327;
          background-color: #eee;
        }
      }
    } 
  }
}
</style>
