import HomePage from '@/views/home';
import AboutUsPage from '@/views/AboutUs';
import IntroducePage from '@/views/Introduce';
import SuccessfulCasePage from '@/views/SuccessfulCase';
import InformationCenterPage from '@/views/InformationCenter';
import MoreInformationPage from '@/views/MoreInformation';
import MapPage from '@/views/Map';

export default [
  {
    path: '',
    component: HomePage
  },
  {
    path: 'AboutUs',
    component: AboutUsPage
  },
  {
    path: 'Introduce',
    component: IntroducePage
  },
  {
    path: 'SuccessfulCase',
    component: SuccessfulCasePage
  },
  {
    path: 'InformationCenter',
    component: InformationCenterPage
  },
  {
    path: 'MoreInformation',
    component: MoreInformationPage
  },
  {
    path: 'Map',
    component: MapPage
  }
];
