<template>
  <div ref="container" class="m-home-banners">
    <div v-if="banners.length > 0" class="banners-ctnr">
      <el-carousel :interval="2500" @change="handleCarouselChange">
        <el-carousel-item class="item-one">
          <img :src="banners[0].imgUrl" alt="">
          <div class="text-one">
            <div :class="{'slide-in-one': currentSlideIndex === 0}">
              <img class="left" src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/banner/security1.png" alt="">
              <img class="center" :src="centerImage" alt="">
              <img class="right" src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/banner/security2.png" alt="">
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="item-two">
          <img :src="banners[1].imgUrl" alt="">
          <div class="text-two">
            <div :class="{'slide-in-two': currentSlideIndex === 1}">
              <img :src="twoImage" alt="">
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item class="item-three">
          <img :src="banners[2].imgUrl" alt="">
          <div class="text-three">
            <div :class="{'slide-in-three': currentSlideIndex === 2}">
              <img class="three" :src="threeImage" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else>
      <p>Loading banners...</p>
    </div>
  </div>
</template>

<script>
import { getBanners } from '@/api/banner';


export default {
  components: {},
  data() {
    return {
      banners: [],
      currentSlideIndex: 0
    };
  },
  computed: {
    getImageUrl() {
      return (index) => {
        const languageUrls = {
          English: 'enurl',
          Chinese: 'curl',
          Japanese: 'jurl',
          Korean: 'kurl',
          India: 'iurl',
        };

        const language = this.$isEnglish
          ? 'English'
          : this.$isChinese
            ? 'Chinese'
            : this.$isJapanese
              ? 'Japanese'
              : this.$isKorean
                ? 'Korean'
                : 'India';

        return this.banners[index][languageUrls[language]];
      };
    },
    centerImage() {
      return this.getImageUrl(0);
    },
    twoImage() {
      return this.getImageUrl(1);
    },
    threeImage() {
      return this.getImageUrl(2);
    },
  },
  created() {
    this.getBanners();
  },
  methods: {
    async getBanners() {
      const res = await getBanners();
      this.banners = res.data.data;
    },
    handleCarouselChange(index) {
      this.currentSlideIndex = index;
    }
  },
  
};
</script>

<style lang="scss">
.m-home-banners {
  margin-top: 20.5rem;
  width: 100%;
  height: 70rem;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.5);

  .el-carousel__container {
    height: 100%;
  }

  .el-carousel {
    width: 100%;
    height: 70rem;

    img {
      width: 100%;
      height: 100%;
    }
    .item-one,
    .item-two,
    .item-three {
      width: 100%;
      height: 100%;
      .text-one {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        .left {
          position: absolute;
          width: 32%;
          height: 88%;
          bottom: 0%;
        }
        .center {
          position: absolute;
          width: 48%;
          height: 65%;
          left: 22%;
          top: 20%;
          z-index: 100;
        }
        .right {
          position: absolute;
          right: 0%;
          width: 32%;
          height: 88%;
          bottom: 0%;
        }
        .slide-in-one {
          .left {
            animation: slide-in-left 1s ease-in-out forwards;
          }
          .center {
            animation: slide-in-top 1s ease-in-out forwards;
          }
          .right {
            animation: slide-in-right 1s ease-in-out forwards;
          }
        }
      }
      .text-two,
      .text-three {
        position: absolute;
        top: 17%;
        left: 8%;
        width: 50%;
        .three {
          width: 90%;
        }
        

        .slide-in-two {
          opacity: 0;
          transform: translateX(-100%);
          animation: slide-enter 1s ease-in-out forwards;
        }
        .slide-in-three {
          opacity: 0;
          transform: translateX(-100%);
          animation: slide-bottom 1s ease-in-out forwards;
        }
      }
    }

  }

  
  @keyframes slide-in-top {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  @keyframes slide-in-left {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }


  @keyframes slide-in-right {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes text-enter {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slide-enter {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slide-bottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}



@media screen and (max-width: 767px) {
  .m-home-banners {
    margin-top: 0rem;
    height: 16rem;
    
    .el-carousel {
      height: 16rem;
      img {
        height: 100%;
      }
      .item-one,
      .item-two,
      .item-three {
        width: 100%;

        .text-one {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        .left {
          position: absolute;
          width: 32%;
          height: 88%;
          bottom: 0%;
        }
        .center {
          position: absolute;
          width: 55%;
          height: 65%;
          left: 22%;
          top: 20%;
          z-index: 100;
        }
        .right {
          position: absolute;
          right: 0%;
          width: 32%;
          height: 88%;
          bottom: 0%;
        }

      }
        .text-two,
        .text-three {
          position: absolute;
          top: 17%;
          left: 8%;

          .slide-in-one {
            opacity: 0;
            transform: translateY(-20px);
            animation: text-enter 1s ease-in-out forwards;
          }

          .slide-in-two {
            opacity: 0;
            transform: translateX(-100%);
            animation: slide-enter 1s ease-in-out forwards;
          }
          .slide-in-three {
            opacity: 0;
            transform: translateX(-100%);
            animation: slide-bottom 1s ease-in-out forwards;
          }
        }
      }
    }
  }
}
</style>
