<template>
  <div class="p-introduce">
    <div class="introduce-title">
      <span class="introduce">{{ $t('about.introduce') }}</span>
      <span class="location">{{ $t('other.location') }}：{{ $t('other.homepage') }} > {{ $t('about.introduce') }}</span>
    </div>
    <!-- <div class="cover"></div> -->
    <!-- 关于我们 -->
    <div class="about">
      <div class="about-header">
        <p class="slide">{{ $t('menu.about') }}</p>
        <!-- <div class="divider"></div>
        <p class="US">{{ $t('menu.about', 'en-US').toUpperCase() }}</p> -->
      </div>
      <div class="about-detail">
        <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about-cover.jpg" alt="">
        <div class="about-introduce">
          <p class="introduce slide-in">{{ $t('enterprise.about') }}</p>
          <p class="slide-in" style="margin-top: 1rem">{{ $t('enterprise.about2') }}</p>
        </div>
      </div>
    </div>
    <!-- 扎根菲律宾，服务全球 -->
    <div class="entrench">
      <div class="entrench-header">
         <p class="slide">{{ $t('enterprise.entrenchTitle') }}</p>
         <!-- <div class="divider"></div>
         <p class="US">{{ $t('enterprise.entrenchTitle', 'en-US').toUpperCase() }}</p> -->
         
      </div>
      <div class="entrench-detail">
        <p>{{ $t('enterprise.entrench') }}</p>
      </div>
    </div>
    <!-- 服务宗旨 -->
    <div class="serve">
      <div class="serve-header">
         <p class="slide">{{ $t('enterprise.purpose') }}</p>
         <!-- <div class="divider"></div>
         <p class="US">{{ $t('enterprise.purpose', 'en-US').toUpperCase() }}</p> -->
         
      </div>
      <div class="serve-detail">
        <p>{{ $t('enterprise.serve') }}</p>
      </div>
    </div>

    <!-- 联系方式 -->
    <div class="contact">
      <div class="contact-header">
        <p>{{ $t('about.relation') }}</p>
         <!-- <div class="divider"></div>
         <p class="US">{{ $t('about.relation', 'en-US').toUpperCase() }}</p> -->
      </div>
      <div class="contact-address">
        <div class="address">
          <p>{{ $t('about.address') }}</p>
          <p><i class="el-icon-location-outline"></i> {{ $t('about.site') }}</p>
        </div>
        <div class="contact-way">
          <div class="way">
            <p>{{ $t('about.email') }}</p>
            <p class="title"><i class="el-icon-message"></i> {{ $t('about.mailbox') }}</p>
          </div>
          <div class="way">
            <p>(+63) 917 180 6668</p>
            <!-- <p>(+63) 945 773 1999</p> -->
            <div class="title" style="display: flex;justify-content: center;align-items: center;">
              <img style="width:2.4rem" src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/phone2.svg" alt="">
              <p> {{ $t('about.relation') }}</p>
            </div>
          </div>
          <div class="way">
            <img src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/qrcode.png" alt="">
            <div class="title" style="display: flex;justify-content: center;align-items: center;">
              <img style="width:3rem" src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icons/qiye.svg" alt="">
              <p>{{ $t('about.WeChat') }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import scrollMixins from '@/mixins/scroll';
import sectionMixins from '@/mixins/section';
import { qs2obj } from "@/utils";
import SectionHeader from "@/views/home/common/SectionHeader";


export default {
  components: { SectionHeader, },
  mixins: [scrollMixins, sectionMixins],
  data() {
    return {
      sectionsData: [],
      scrollBox: null,
      scrollBoxEl: null,
      activeTab: 'instructions',
      activeComponent: 'Instructions',
      content: []
    };
  },
  mounted() {
    this.setSectionData([
      {
        name: 'contact',
        el: this.$refs.contact
      }
    ]);

    setTimeout(() => {
      const search = window.location.search;

      if (search) {
        const pairs = qs2obj(search);

        if (pairs && pairs.section) {
          this.toSection(pairs.section);
        }
      }
    }, 0);
  },
  methods: {
    setCurrentSection(section) {
      this.$store.actions.setCurrentSection(section.name);
    },
    setSectionController(controller) {
      this.$store.mutations.setSectionController(controller);
    },
    switchTo(tab) {
      this.activeTab = tab.value;
      this.activeComponent = tab.component;
    }
  }
};
</script>

<style lang="scss">
.p-introduce {
  margin-top: 20.5rem;
  background-color: #1b1b1b;
  .US {
    font-size: 3rem;
  }
  .divider {
    margin: 1.1rem auto;
    width: 5rem;
    height: 3px;
    background-color: #ebc83e;
    // background-color: #fe0608;
    border-radius: 2px;
  }
  .introduce-title {
    max-width: 115rem;
    margin: 0 auto;
    height: 6rem;
    background-color: #1b1b1b;
    .introduce {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 6rem;
      float: left;
    }
    .location {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 6rem;
      float:right;
    }
  }
  .slide {
    // opacity: 0;
    transform: translateY(-100%);
    animation: slide-in 0.5s forwards;
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .cover {
    position: relative;
    height: 65rem;
    padding-top: 13rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/banner1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .about {
    padding: 7rem 0;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .about-header {
      font-size: 4rem;
      color: #fff;
      line-height: 7rem;
      
    }
    .about-detail {
      display: flex;
      padding: 5rem 0;
      justify-content: space-evenly;
      align-items: center;
      color: #fff;
      max-width: 125rem;
      margin: 0 auto;
      img {
        width: 55rem;
        height: 45rem;
        transform: translateX(-100%) translateY(100%);
        animation: slide-in 1s forwards;
      }
      @keyframes slide-in {
        0% {
          transform: translateX(-100%) translateY(100%);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      .about-introduce {
        padding-left: 4rem;
        p{
          font-size: 2rem;
          text-align: left;
          text-indent: 2em;
          line-height: 4rem;
        }
        .slide-in {
          opacity: 0;
          transform: translate(50%, 50%);
          animation: slideIn 1s forwards;
        }

        @keyframes slideIn {
          0% {
            opacity: 0;
            transform: translate(50%, 50%);
          }
          100% {
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }
    }
  }
  .entrench {
    padding: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/4.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .entrench-header {
      font-size: 4rem;
      color: #fff;
      line-height: 5rem;
    }
    .entrench-detail {
      color: #fff;
      max-width: 110rem;
      margin: 0 auto;
      p{
        margin-top: 4rem;
        font-size: 2.2rem;
        text-align: left;
        text-indent: 2em;
        line-height: 5rem;
      }
      
    }
  }
  .serve {
    padding: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/6.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .serve-header {
      font-size: 4rem;
      color: #fff;
    }
    .serve-detail {
      color: #fff;
      max-width: 100rem;
      margin: 0 auto;
      p{
        margin-top: 4rem;
        font-size: 2.2rem;
        text-align: left;
        text-indent: 2em;
        line-height: 5.5rem;
      }
      
    }
  }
  .honor {
    height: 85rem;
    padding-top: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/6.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // margin: 0 auto;
    text-align: center;
    .honor-certificate {
      max-width: 100rem;
      margin: 0 auto;
      .certificate {
        float: left;
        padding: 8rem 10rem;
        img {
          width: 30rem;
        }
        p {
          color: #fff;
          font-size: 2rem;
          line-height: 6rem;
        }
      }
    }
  }
  .contact {
    height: 60rem;
    padding-top: 8rem;
    background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/8.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    .contact-header {
      font-size: 4rem;
      color: #fff;
      line-height: 5rem;
    }
    .contact-address {
      color: #fff;
      max-width: 90rem;
      margin: 2rem auto;
      .address {
        font-size: 2.2rem;
        text-align: center;
        line-height: 4rem;
        padding: 2rem 0;
      }
      p{
        font-size: 2.2rem;
        text-align: center;
        line-height: 4rem;
      }
      
      .contact-way {
        margin-top: 4rem;
        height: 20rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .way {
          img {
            width: 14rem;
          }
          .title {
            margin-top: 1rem;
            p {
              margin-left: 0.1rem;
            }
          }
        }
      }
    }
  }
  
}

@media screen and (max-width: 767px) {
  .p-introduce {
    margin-top: 0;
    .US {
      font-size: 1.5rem;
    }
    .divider {
      margin: 0.8rem auto;
      width: 5rem;
      height: 2px;
      background-color:#ebc83e;
      border-radius: 2px;
    }
    .introduce-title {
      display: none;
      // max-width: 35rem;
      // margin: 0 auto;
      // height: 5rem;
      // background-color: #1b1b1b;
      .introduce {
        color: #fff;
        font-size: 1.35rem;
        font-weight: 600;
        line-height: 6rem;
        float: left;
      }
      .location {
        color: #fff;
        font-size: 1.15rem;
        font-weight: 400;
        float:right;
      }
    }
    .cover {
      margin-top: 0rem;
      height: 20rem;
      padding-top: 7rem;
    }
    .about-ct-ctnr {
      padding: 2.6rem 2rem 0;
    }
    .about {
      // height: 45rem;
      padding: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      .about-header {
        font-size: 2rem;
        color: #fff;
        max-width: 40rem;
        margin: 0 auto;
      }
      .about-detail {
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem 0;
        align-items: center;
        color: #fff;
        // max-width: 125rem;
        margin: 0 auto;
        img {
          width: 32rem;
          height: 20rem;
        }
        .about-introduce {
          padding-left: 0rem;
          .introduce {
            margin-top: 1rem;
          }
          p{
            font-size: 1.4rem;
            text-align: left;
            text-indent: 2em;
            line-height: 3rem;
          }
        }
      }
    }

    .entrench {
      padding: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/4.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      .entrench-header {
        font-size: 2rem;
        color: #fff;
        max-width: 45rem;
        margin: 0 auto;
      }
      .entrench-detail {
        color: #fff;
        margin: 0 auto;
        p{
          margin-top: 2rem;
          font-size: 1.3rem;
          text-align: left;
          text-indent: 2em;
          line-height: 3rem;
        }
      }
    }
    .serve {
      padding: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/4.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      .serve-header {
        font-size: 2rem;
        color: #fff;
        max-width: 40rem;
        margin: 0 auto;
        line-height: 5rem;
      }
      .serve-detail {
        color: #fff;
        // padding: 0 2rem;
        margin: 0 auto;
        p{
          margin-top: 2rem;
          font-size: 1.3rem;
          text-align: left;
          text-indent: 2em;
          line-height: 3rem;
        }
      }
    }

    
    .contact {
      height: 70rem;
      padding-top: 3rem;
      background-image: url("https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/about/8.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      text-align: center;
      .contact-header {
        font-size: 2rem;
        // max-width: 16rem;
        // margin: 1rem auto;
        line-height: 5rem;
      }
      .contact-address {
        padding: 0 4rem;
        margin-top: 0;
        .address {
          font-size: 1.4rem;
        }
        p {
          font-size: 1.4rem;
          margin-top: 1rem;
          line-height: 3rem;
        }
        .contact-way {
          height: 40rem;
          margin-top: 1rem;
          max-width: 50rem;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          .way {
            p:nth-child(2){
              margin-top: 0rem;
            }
          }

        }
      }
    }
  }
  
}
</style>

