<template>
  <div class="m-home-services-item">
    <div class="services-item-ctnr">
      <div class="icon">
        <LazyLoadImg :src="service.icon" height="100%"></LazyLoadImg>
      </div>
      <p class="title">{{ service.title }}</p>
      <!-- <p class="sub-title">{{ service.subTitle }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.m-home-services-item {
  text-align: center;
  color: #aaa;
  .services-item-ctnr {
    margin: 0 auto;
    padding: 2rem 0 ;
    max-width: 25rem;
    .icon {
      margin: 0 auto;
      max-width: 9rem;
      height: 11rem;
    }
    .title {
      margin-top: 1.5rem;
      padding: 0 0.5rem;
      font-size: 2.2rem;
      font-weight: bold;
    }
    .sub-title {
      margin-top: 0.5rem;
      padding: 0 0.5rem;
      min-height: 4rem;
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .m-home-services-item {
    padding: 0.3rem;
    .services-item-ctnr {
      padding: 1.4rem 0;
      .icon {
        //max-width: 7rem;
        max-width: 4.6rem;
        height: 5rem;
      }
      .title {
        font-size: 1.2rem;
      }
      .sub-title {
        font-size: 1rem;
        min-height: 0;
      }
    }
  }
}
</style>
