<template>
  <div ref="container" class="m-home-services">
    <div class="services-title">
      <p>{{ $t('title.company') }}</p>
    </div>
    <div class="services-ctnr animation" :class="{ 'fade-to-top': animated }">
      <Service
        class="service-item"
        v-for="(service, index) in services"
        :key="index"
        :service="service"
      ></Service>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/views/home/common/SectionHeader';
import Service from './Service';
import { services } from './services';
import animationMixin from '@/mixins/animations';

export default {
  components: { SectionHeader, Service },
  mixins: [animationMixin],
  data() {
    return {
      services: services.map((service) => ({
        icon: service.icon,
        title: this.$t(service.title),
      })),
    };
  },
};
</script>

<style lang="scss">
.m-home-services {
  margin: 0 auto;
  padding: 8rem 0 8rem;
  background-color: #111111;
  background-image: url('https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/home/7.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .services-title {
    margin-bottom: 6rem;
    font-size: 4rem;
    color: #ededed;
  }
  .services-ctnr {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4列 */
    grid-gap: 2rem;
    margin: 2rem auto;
    max-width: 130rem;
  }
}
@media screen and (max-width: 767px) {
  .m-home-services {
    // height: 48rem;
    padding: 4rem 0 2rem;
    .services-title {
      margin: 0 auto;
      max-width: 30rem;
    }
    .services-title {
      font-size: 2.2rem;
      color: #ededed;
    }
    .services-ctnr {
      margin-top: 1.5rem;
      grid-gap: 0.1rem;
      margin: 1rem auto;
      max-width: 100%;
      .service-item {
        margin-top: 1rem;
      }
    }
  }
}
</style>
