<template>
  <div id="app">
    <div class="app-container" :class="`${langClass} ${sideBarStateClass}`">
      <div class="app-content">
        <AppHeader></AppHeader>
        <AppMobileHeader></AppMobileHeader>
        <router-view></router-view>
        <AppFooter></AppFooter>
        <Align></Align>
      </div>
      <AppSideBar class="app-sidebar"></AppSideBar>
    </div>
   <BottomActions></BottomActions>
  </div>
</template>

<script>
import AppHeader from "./Header";
import AppMobileHeader from "./MobileHeader";
import AppFooter from "./Footer";
import AppSideBar from "./SideBar";
import Align from "./Align";
import BottomActions from "./BottomActions"
import IM from './IM';

export default {
  components: {
    AppHeader,
    AppFooter,
    AppMobileHeader,
    AppSideBar,
    Align,
    IM,
    BottomActions
  },
  computed: {
    langClass() {
      return this.$isEnglish ? "lang_en" : "lang_cn";
    },
    sideBarStateClass() {
      return this.$store.state.mobileSideBarState
        ? "sidebar__activated"
        : "sidebar";
    },
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  height: 100%;
  //overflow-x: hidden;
  //overflow-y: auto;
  .app-container {
    position: relative;
    height: 100%;
    .app-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 100%;
      padding: 0 0 8rem 0;
      //padding: 14.5rem 0 8rem 0;
      transition: all 0.2s ease;
      background-color: #111;
    }
    .app-sidebar {
      position: fixed;
      right: 0;
      top: 5rem;
      width: 0;
      z-index: 2;
      transition: all 0.2s ease;
      overflow: hidden;
    }
    &.sidebar__activated {
      .app-sidebar {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #app {
    .app-container {
      .app-content {
        background-color: #111;
        padding: 5rem 0 8rem 0;
      }
    }
  }
}
</style>
