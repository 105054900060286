import Vue from "vue";
import { homeMenu } from "@/config/menu";
import request from "@/utils/request";

const menu = {
  home: homeMenu
};

export const state = Vue.observable({
  banners: null,
  pageMenu: homeMenu,
  mobileSideBarState: false,
  telephonePageState: false,
  qrCodePageState: false,
  currentSection: '',
  currentSectionName: '',
  sectionChangeListeners: [],
  sectionController: null,
  baseinfo: null
});

export const mutations = {
  switchMobileSideBarState() {
    state.mobileSideBarState = !state.mobileSideBarState;
  },
  setMobileSideBarState(s) {
    state.mobileSideBarState = s;
  },
  switchTelephonePageState() {
    state.telephonePageState = !state.telephonePageState;
  },
  setTelephonePageState(s) {
    state.telephonePageState = s;
  },
  switchQrCodePageState() {
    state.qrCodePageState = !state.qrCodePageState;
  },
  setQrCodePageState(s) {
    state.qrCodePageState = s;
  },
  setPageMenu(menuKey) {
    state.pageMenu = menu[menuKey] || menu[0].menu;
  },
  setSectionController(controller) {
    state.sectionController = controller;
  },
  setCurrentSection(sectionValue) {
    state.currentSection = sectionValue;
  },
  setCurrentSectionName(sectionName) {
    state.currentSectionName = sectionName;
  },
  addSectionChangeListener(listener) {
    if (listener) {
      state.sectionChangeListeners.push(listener);
    }
  },
  removeSectionChangeListener(listener) {
    const index = state.sectionChangeListeners.findIndex(
      (item) => item === listener
    );

    if (index !== -1) {
      state.sectionChangeListeners.splice(index, 1);
    }
  }
};

export const actions = {
  setPageMenu(menuKey) {
    mutations.setPageMenu(menuKey);

    if (state.pageMenu && state.pageMenu.length) {
      mutations.setCurrentSection(state.pageMenu[0].sectionValue);
      mutations.setCurrentSectionName(state.pageMenu[0].name);
    }
  },
  setCurrentSection(sectionName) {
    mutations.setCurrentSection(sectionName);
    const menuItem = state.pageMenu.find(item => item.sectionValue === sectionName);

    if (menuItem) {
      mutations.setCurrentSectionName(menuItem.name);
    } else {
      mutations.setCurrentSectionName('');
    }

    this.runSectionChangeListeners(sectionName);
  },
  goToSection(sectionName) {
    this.setCurrentSection(sectionName);

    if (state.sectionController && state.sectionController.toSection) {
      state.sectionController.toSection(sectionName);
    }
  },
  addSectionChangeListener(listener) {
    mutations.addSectionChangeListener(listener);
  },
  removeSectionChangeListener(listener) {
    mutations.removeSectionChangeListener(listener);
  },
  runSectionChangeListeners(sectionName) {
    state.sectionChangeListeners.forEach((fn) => {
      if (fn) {
        fn(sectionName);
      }
    });
  },
  fetchBaseInfo() {
    return request({
      method: 'get',
      url: '/api/baseinfo',
    }).then(res => {
      state.baseinfo = res.data;
    })
  },
  addPV() {
    return request({
      method: 'get',
      url: '/api/addPV'
    });
  },
  getPVChartData() {
    return request({
      method: 'get',
      url:'/api/getPVChartData'
    })
  },
  likeWebsite() {

  }
};

export default {
  state,
  mutations,
  actions,
};
