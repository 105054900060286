export default {
  data() {
    return {
      sectionsData: [],
    };
  },
  mounted() {
    this.setSectionController({
      toSection: this.toSection
    });
  },
  methods: {
    setSectionData(sectionArr) {
      if (Array.isArray(sectionArr) && sectionArr.length) {
        this.sectionsData = sectionArr.map((section) => ({
          name: section.name,
          offsetTop: this.getSectionTop(section.el),
        }));
      }
    },
    getSectionTop(sectionEl) {
      if (sectionEl) {
        if (sectionEl.$el) {
          sectionEl = sectionEl.$el;
        }

        return sectionEl.offsetTop;
      }
    },
    toSection(sectionName) {
      const section = this.sectionsData.find(
        (section) => section.name === sectionName
      );

      if (section) {
        this.toScrollTop(section.offsetTop);
      }
    },
    scrollListener(scrollTop) {
      // 加上header的高度
      const treatedScrollTop = scrollTop + (this.$isMobile ? 60 : 150);
      let currentSection;
      for (let i = this.sectionsData.length - 1; i >= 0; i--) {
        let section = this.sectionsData[i];
        if (section.offsetTop <= treatedScrollTop) {
          currentSection = section;

          break;
        }
      }
      // alert(currentSection.name + scrollTop);
      if (currentSection) {
        this.setCurrentSection(currentSection);
      }
    }
  },
};
