<template>
  <div class="m-home-mobile-bottom-actions slide">
    <div class="actions-ctnr">
      <div class="email" @click="emailFn">
        <a href="" target="_blank">
          <img
            class="image"
            src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/3/email.png"
            alt=""
          />
          <p class="tt">{{ $t('other.email') }}</p>
        </a>
      </div>
      <div class="line"></div>
      <div class="wechat">
        <a :href="wechatServiceUrl" target="_blank">
          <img
            class="image"
            src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/3/weixin.png"
            alt=""
          />
          <p class="tt">{{ $t('other.wechat') }}</p>
        </a>
      </div>
      <div class="line"></div>
      <div class="facebook">
        <a :href="facebookServiceUrl" target="_blank">
          <img
            class="image"
            src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/3/facebook.png"
            alt=""
          />
          <p class="tt">{{ $t('other.facebook') }}</p>
        </a>
      </div>
      <div class="line"></div>
      <div class="whatsApp">
        <a :href="PCWhatsAppServiceUrl" target="_blank">
          <img
            class="image"
            src="https://365-bodyguard.oss-cn-beijing.aliyuncs.com/image/icon/3/WhatsApp.png"
            alt=""
          />
          <p class="tt">WhatsApp</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isJumping: false,
      onlineServiceUrl:
        'https://chaten.sobot.com/chat/pc/v2/index.html?sysnum=102733ed62dd4e35b7a072da6cae8a6c',
      emailServiceUrl: 'security@365theone.com',
      facebookServiceUrl: 'https://www.facebook.com/365TheOne',
      wechatServiceUrl: 'https://work.weixin.qq.com/kfid/kfc40e8c04c97695374',
      APPWhatsAppServiceUrl: 'https://api.whatsapp.com/send?phone=639457731999',
      PCWhatsAppServiceUrl: 'https://api.whatsapp.com/send?phone=639457731999',
      // APPWhatsAppServiceUrl: 'https://wa.me/message/IEXWNZ2PNKLID1',
      // PCWhatsAppServiceUrl: 'https://wa.me/message/IEXWNZ2PNKLID1',
    };
  },
  mounted() {
    this.startJumpAnimation();
  },
  methods: {
    startJumpAnimation() {
      const images = document.querySelectorAll('.image');
      let currentImageIndex = 0;

      const animateImage = () => {
        images[currentImageIndex].classList.add('jump-animation');

        setTimeout(() => {
          images[currentImageIndex].classList.remove('jump-animation');
          currentImageIndex = (currentImageIndex + 1) % images.length;
          animateImage();
        }, 1000);
      };

      animateImage();
    },
    emailFn() {
      const recipient = 'security@365theone.com';
      const subject = '';
      const body = '';
      const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoUrl;
    },
  },
};
</script>

<style lang="scss">
.m-home-mobile-bottom-actions {
  // display: none;
}
@media screen and (min-width: 767px) {
  .m-home-mobile-bottom-actions {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .m-home-mobile-bottom-actions {
    position: fixed;
    left: 2.5%;
    bottom: 0.8rem;
    width: 95%;
    height: 6.5rem;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
    border-radius: 3rem;
    border: 2px solid #868686;
    background: #000;
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);

    animation: glowing 2s ease-in-out infinite;

    @keyframes glowing {
      0% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      }
      50% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
      }
      100% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      }
    }

    .actions-ctnr {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .email,
    .wechat,
    .service,
    .facebook,
    .whatsApp {
      text-align: center;
      a {
        height: 6.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        img {
          // background-color: #fff;
          width: 2.9rem;
          border-radius: 0.5rem;
        }
        .tt {
          display: inline-block;
          font-size: 1.2rem;
        }
        .jump-animation {
          animation: jumpAnimation 2s infinite;
        }

        @keyframes jumpAnimation {
          0%,
          100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }

        @keyframes jumpAnimation {
          0%,
          50%,
          100% {
            transform: translateY(0);
          }
          25% {
            transform: translateY(-10px);
          }
          75% {
            transform: translateY(-5px);
          }
        }
      }
    }

    .line {
      height: 2rem;
      width: 0.1rem;
      border: 0.1rem solid #fff;
    }
  }
}
</style>
